import { useCallback } from 'react'

import { useQuerySizing } from '~/hooks-queries'
import { useQueryAvatar } from '~/hooks-queries/avatar'
import { IFormSendAvatarImage, IUpdateAvatarMeasurements } from '~/hooks-queries/sizing'
import { filterAvatarsUsingPartnerKey, transformedDataMeasurements } from '~/hooks/useAvatar/utils'
import { usePrepareImage } from '~/hooks/usePrepareImage'

import { useAvatarContext } from '~/context/Avatar'

import { IAvatar } from '~/entities'
import { getPartnerAPIKey } from '~/utils/getPartnerAPIKey'
import Tracking from '~/utils/tracking'

import { LOCAL_STORAGE_AVATARS_KEY } from './constants'
import {
  IUseAvatar,
  TCreateAvatar,
  TGetAvatarMeasurements,
  TGetModelToTryOnByUUID,
  TSendAvatarImage,
  TSendAvatarInfos,
  TUpdateAvatarInfos,
  TUpdateAvatarMeasurements,
} from './types'

export const useAvatar = (): IUseAvatar => {
  const { convertToFile } = usePrepareImage()
  const { sendImage, sendInfos, fetchMeasurements, updateMeasurements } = useQuerySizing()
  const { fetch } = useQueryAvatar()
  const { stateAvatar } = useAvatarContext()

  const getAvatarsFromLocalStorage = useCallback(() => {
    const storage = localStorage.getItem(LOCAL_STORAGE_AVATARS_KEY)
    const avatars: Array<IAvatar> = storage ? JSON.parse(storage) : []

    return avatars
  }, [])

  const getAvatars = useCallback(
    (id?: number): Array<IAvatar> => {
      const avatars: Array<IAvatar> = getAvatarsFromLocalStorage()
      const filteredAvatars = filterAvatarsUsingPartnerKey({ items: avatars })

      return id ? filteredAvatars.filter(avatar => avatar.id === id) : filteredAvatars
    },
    [getAvatarsFromLocalStorage],
  )

  const getAvatarByUuid = useCallback(
    (avatar_uuid: string): IAvatar | undefined => {
      const avatars: Array<IAvatar> = getAvatarsFromLocalStorage()
      const filteredAvatars = filterAvatarsUsingPartnerKey({ items: avatars })

      return filteredAvatars.find(avatar => avatar.avatar_uuid === avatar_uuid)
    },
    [getAvatarsFromLocalStorage],
  )

  const setAvatar = useCallback(
    (avatar: IAvatar) => {
      const list = getAvatarsFromLocalStorage()

      avatar.apiKey = getPartnerAPIKey()

      localStorage.setItem(LOCAL_STORAGE_AVATARS_KEY, JSON.stringify([avatar, ...list]))
    },
    [getAvatarsFromLocalStorage],
  )

  const deleteAvatar = useCallback(
    (id: number): boolean => {
      const list = getAvatars()
      const index = list.findIndex(avatar => avatar.id === id)

      if (index === -1) return false

      list.splice(index, 1)

      if (!list.length) {
        localStorage.removeItem(LOCAL_STORAGE_AVATARS_KEY)

        return true
      }

      localStorage.setItem(LOCAL_STORAGE_AVATARS_KEY, JSON.stringify(list))

      return true
    },
    [getAvatars],
  )

  const updateAvatar = useCallback(
    (id: number, data: Partial<IAvatar>) => {
      const avatarsList = getAvatarsFromLocalStorage()
      const index = avatarsList.findIndex(avatar => avatar.id === id)

      if (index === -1) return

      avatarsList[index] = { ...avatarsList[index], ...data }

      localStorage.removeItem(LOCAL_STORAGE_AVATARS_KEY)
      localStorage.setItem(LOCAL_STORAGE_AVATARS_KEY, JSON.stringify(avatarsList))
    },
    [getAvatarsFromLocalStorage],
  )

  const hasAvatarMeasurements = useCallback(
    (avatar_uuid: string): boolean => {
      const avatar = getAvatarByUuid(avatar_uuid)

      return !!(avatar?.age && avatar?.height && avatar?.weight)
    },
    [getAvatarByUuid],
  )

  const updateAvatarInfos = useCallback(
    ({ data, setState }: TUpdateAvatarInfos) =>
      setState(current => ({ ...current, data: { ...current?.data, ...data } })),
    [],
  )

  const createAvatar = useCallback(
    async ({ data, setState }: TCreateAvatar) => {
      const file = await convertToFile(data, 'image/jpeg')
      const form = new FormData()

      form.append('image', file)
      form.append('gender', stateAvatar?.data?.gender || 'MALE')
      form.append('age', stateAvatar?.data?.age?.toString() || '')
      form.append('height', stateAvatar?.data?.height?.toString() || '')
      form.append('weight', stateAvatar?.data?.weight?.toString() || '')

      fetch({
        payload: form,
        callbackOnSuccess: avatar => {
          setState(current => ({ ...current, data: { ...current?.data, ...avatar } }))
        },
        callbackOnError: error => setState(current => ({ ...current, errors: { ...current?.errors, create: error } })),
        callbackOnProcessing: status =>
          setState(current => ({
            ...current,
            errors: { ...current?.errors, create: status ? undefined : current?.errors?.create },
            isLoading: status,
          })),
      })
    },
    [
      convertToFile,
      fetch,
      stateAvatar?.data?.age,
      stateAvatar?.data?.gender,
      stateAvatar?.data?.height,
      stateAvatar?.data?.weight,
    ],
  )

  const sendAvatarImage = useCallback(
    async ({ data, setState }: TSendAvatarImage) => {
      const file = await convertToFile(data.image, 'image/jpeg')
      const form: IFormSendAvatarImage = new FormData()
      const position = data.type.toLowerCase()

      form.append('avatar_uuid', data.avatar_uuid)
      form.append('image', file)
      form.append('type', data.type)

      sendImage({
        payload: form,
        callbackOnSuccess: image =>
          setState(current => ({
            ...current,
            positions: {
              ...current?.positions,
              [position]: {
                ...current?.positions[position],
                data: image,
              },
            },
          })),
        callbackOnError: error =>
          setState(current => ({
            ...current,
            positions: {
              ...current?.positions,
              [position]: {
                ...current?.positions[position],
                error,
              },
            },
          })),
        callbackOnProcessing: status =>
          setState(current => ({
            ...current,
            positions: {
              ...current?.positions,
              [position]: {
                ...current?.positions[position],
                error: status ? undefined : current?.positions[position]?.error,
                isLoading: status,
                called: true,
              },
            },
          })),
      })
    },
    [sendImage, convertToFile],
  )

  const sendAvatarInfos = useCallback(
    ({ data, setState }: TSendAvatarInfos) =>
      sendInfos({
        payload: data,
        callbackOnSuccess: () => {
          Tracking.logEvent('AVATAR_SIZE', {
            avatar: data.avatar_uuid,
            gender: data.gender,
            height: data.height,
            weight: data.weight,
            age: data.age,
            widget: true,
          })

          updateAvatarInfos({ data, setState })
        },
        callbackOnError: error => setState(current => ({ ...current, errors: { ...current?.errors, update: error } })),
        callbackOnProcessing: status =>
          setState(current => ({
            ...current,
            errors: { update: status ? undefined : current?.errors?.update },
            isLoading: status,
            called: true,
          })),
      }),
    [sendInfos, updateAvatarInfos],
  )

  const resetAvatar = useCallback(({ data, setState }) => setState(data), [])

  const getAvatarMeasurements = useCallback(
    ({ data, callbackOnSuccess }: TGetAvatarMeasurements) => {
      fetchMeasurements({
        payload: { avatar_uuid: data.avatar_uuid },

        callbackOnSuccess: measurements => {
          callbackOnSuccess(transformedDataMeasurements(measurements))
        },
      })
    },
    [fetchMeasurements],
  )

  const getModelToTryOnByUUID = useCallback(
    ({ data, callbackOnSuccess, callbackOnError }: TGetModelToTryOnByUUID) => {
      fetchMeasurements({
        payload: { avatar_uuid: data.avatar_uuid },

        callbackOnSuccess: measurements => {
          callbackOnSuccess(measurements.length ? measurements[0] : null)
        },
        callbackOnError: () => {
          callbackOnError()
        },
      })
    },
    [fetchMeasurements],
  )

  const updateAvatarMeasurements = useCallback(
    ({ data, setState }: TUpdateAvatarMeasurements) => {
      const payload: IUpdateAvatarMeasurements = {
        avatar_uuid: data.values.avatar_uuid,
      }

      Object.keys(data.values).forEach(key => {
        if (data.type === 'update' && !data.values[key]) {
          return
        }

        payload[key] = data.values[key]
      })

      updateMeasurements({
        payload,
        callbackOnSuccess: () => setState({ status: true, data: payload }),
        callbackOnProcessing: isLoading =>
          setState({ isLoading, called: true, data: !isLoading ? payload : undefined }),
        callbackOnError: error => setState({ status: false, error, data: payload }),
      })
    },
    [updateMeasurements],
  )

  const userHasSelfAvatar = (): boolean => {
    const avatars = getAvatars()
    const hasSelfAvatar = avatars.some((a: IAvatar) => a.weight && a.height && a.age)

    return hasSelfAvatar
  }

  return {
    getAvatars,
    getAvatarByUuid,
    setAvatar,
    deleteAvatar,
    updateAvatar,
    hasAvatarMeasurements,
    updateAvatarInfos,
    sendAvatarImage,
    sendAvatarInfos,
    createAvatar,
    resetAvatar,
    getAvatarMeasurements,
    getModelToTryOnByUUID,
    updateAvatarMeasurements,
    userHasSelfAvatar,
  }
}
