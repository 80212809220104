/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useMemo, useState } from 'react'

import { useQueryGenders, useQueryModels, useQuerySkins } from '~/hooks-queries'
import { useAvatar } from '~/hooks/useAvatar'
import { useCurrentModel } from '~/hooks/useCurrentModel'
import { ICurrentModel } from '~/hooks/useCurrentModel/types'
import { useMeasurements } from '~/hooks/useMeasurements'
import { usePartner } from '~/hooks/usePartner'
import { useTryon } from '~/hooks/useTryon'
import { TryonStorageInstance } from '~/hooks/useTryon/storage'

import { useMeasurementsContext } from '~/context/Measurements'
import { useNavigation } from '~/context/Navigation'
import { useSyncContext } from '~/context/Sync'
import { useTryonContext } from '~/context/Tryon'
import { useUserContext } from '~/context/User'

import { Backdrop } from '~/components/Backdrop'
import Box from '~/components/Box'
import ColorsRadio from '~/components/ColorsRadio'
import InstructionalCard from '~/components/InstructionalCard'
import ModelsList from '~/components/ModelsList'
import { QRCode } from '~/components/QRCode'
import { RoutesHeader } from '~/components/RoutesHeader'
import { Select } from '~/components/Select'
import { ISelfAvatarsListItemProps } from '~/components/SelfAvatarsList/types'
import {
  ITabDefaultContentProps,
  ITabDefaultHeaderProps,
  TabDefaultContent,
  TabDefaultHeader,
  TabDefaultWrapper,
  TabHorizontalList,
} from '~/components/Tab'
import { TabUseMyPhotoUpload } from '~/components/TabUseMyPhotoUpload'
import { TextButton } from '~/components/TextButton'

import theme from '~/theme'

import { IModel } from '~/entities'
import { slides } from '~/screens/Models/constants'
import { isCameraFlow } from '~/utils/isCameraFlow'
import Tracking from '~/utils/tracking'
import { translate } from '~/utils/translate'

import * as Styled from './styles'
import { appendParameterToUrl } from './utils/appendParameterToUrl'

export const Models = () => {
  const { getAvatars, deleteAvatar } = useAvatar()
  const [tabActive, setTabActive] = useState(0)
  const [selectedGender, setSelectedGender] = useState<number>()
  const [selectedSkin, setSelectedSkin] = useState<number>()
  const [selectedModel, setSelectedModel] = useState<string>()
  const [componentDisabled, setComponentDisabled] = useState(true)
  const [modelsLoading, setModelsLoading] = useState(true)
  const [visibleBackdrop, setVisibleBackdrop] = useState(false)
  const [avatarDelete, setAvatarDelete] = useState<ISelfAvatarsListItemProps>()
  const [hasTabUseModelActive, setHasTabUseModelActive] = useState(false)
  const { clearStates, setTryonState, stateCurrentTryon } = useTryonContext()
  const { startTryon, removeTryon } = useTryon()
  const { clearStates: clearMeasurementsState } = useMeasurementsContext()
  const { resetMeasurements } = useMeasurements()
  const { stateCurrentUser } = useUserContext()
  const { getPartner } = usePartner()
  const { startSession, startSubscription } = useSyncContext()

  const { navigate, params } = useNavigation()
  const { getCurrentModel, setCurrentModel, getDefaultModels } = useCurrentModel()
  const [selectedModelIndex, setSelectedModelIndex] = useState<number>()

  const showCameraFlow = useMemo(() => isCameraFlow(), [])

  const [listAvatar, setListAvatar] = useState<ISelfAvatarsListItemProps[]>(
    getAvatars().map(avatar =>
      avatar.avatar_uuid === (getCurrentModel() && getCurrentModel()?.id)
        ? { ...avatar, active: true }
        : { ...avatar, active: false },
    ),
  )
  const { fetchGenders, data: gendersData } = useQueryGenders()
  const { fetchSkins, data: skinsData } = useQuerySkins()
  const { fetchModels, data: modelsData } = useQueryModels()

  const mobilePath = useMemo(() => {
    const payload = [
      { key: 'dwoa', value: '1' },
      { key: 'dwuser', value: stateCurrentUser?.uuid as string },
      { key: 'dwuuid', value: getCurrentModel().id },
      { key: 'dwgender', value: getCurrentModel().gender as string },
    ]

    const products: (string | undefined)[] = [
      TryonStorageInstance.get()?.top?.identifier,
      TryonStorageInstance.get()?.bottom?.identifier,
      TryonStorageInstance.get()?.full?.identifier,
    ].filter(Boolean)

    if (products.length) {
      payload.push({
        key: 'dwskus',
        value: products.join(','),
      })
    }
    return appendParameterToUrl(payload)
  }, [getCurrentModel, stateCurrentUser?.uuid])

  const handleClose = useCallback(
    ({ openCombine }) => (openCombine ? navigate('Home', { isCombineActive: true }) : navigate('Home')),
    [navigate],
  )

  const updateModel = useCallback(
    async (model: ICurrentModel, redirect = true) => {
      const currentModel = getCurrentModel()

      if (model.id === currentModel?.id) {
        handleClose({})

        return
      }

      setComponentDisabled(true)
      setCurrentModel({
        id: model.id,
        skin: model.skin,
        stage_image: model.stage_image,
        gender: model.gender,
        type: model.type,
      })

      Tracking.logEvent('AVATAR_SELECT', {
        avatar: model.id,
        type: model.type === 'PRE_MODEL' ? 'pre' : 'self',
        widget: true,
      })

      resetMeasurements({ data: ['stateCurrentMeasurements'], setState: clearMeasurementsState })

      if (
        model.gender &&
        currentModel?.gender &&
        model.gender !== currentModel?.gender &&
        stateCurrentTryon?.products.top?.gender !== 'UNISEX' &&
        stateCurrentTryon?.products.bottom?.gender !== 'UNISEX'
      ) {
        removeTryon({ setState: clearStates })
        handleClose({ openCombine: true })

        return
      }

      const currentStorage = TryonStorageInstance.get() || {}

      if (!Object.keys(currentStorage).length) {
        redirect && handleClose({ openCombine: true })

        return
      }

      const topProduct = currentStorage.top
      const bottomProduct = currentStorage.bottom

      const { data: partner } = await getPartner()
      if (
        topProduct?.gender === 'UNISEX' &&
        bottomProduct?.gender !== 'UNISEX' &&
        model.gender !== currentModel.gender
      ) {
        startTryon({
          data: {
            idModel: model.id,
            from: 'model',
            products: { top: topProduct },
            upscale: partner?.upscale,
          },
          setState: setTryonState,
        })

        return redirect && handleClose({ openBackdrop: true })
      }

      if (
        topProduct?.gender !== 'UNISEX' &&
        bottomProduct?.gender === 'UNISEX' &&
        model.gender !== currentModel.gender
      ) {
        startTryon({
          data: {
            idModel: model.id,
            from: 'model',
            products: { bottom: bottomProduct },
            upscale: partner?.upscale,
          },
          setState: setTryonState,
        })

        return redirect && handleClose({ openBackdrop: true })
      }

      startTryon({
        data: {
          idModel: model.id,
          from: 'model',
          products: currentStorage,
          upscale: partner?.upscale,
        },
        setState: setTryonState,
      })

      redirect && handleClose({ openCombine: true })
    },
    [
      getCurrentModel,
      setCurrentModel,
      handleClose,
      startTryon,
      setTryonState,
      removeTryon,
      clearStates,
      resetMeasurements,
      clearMeasurementsState,
      stateCurrentTryon,
      getPartner,
    ],
  )

  const handleModelSelect = useCallback(
    (model: IModel) => {
      updateModel({
        id: model.details.id,
        gender: model.details.gender,
        skin: model.details.skin_tone_id,
        stage_image: model.details.stage_image,
        type: 'PRE_MODEL',
      })
    },
    [updateModel],
  )

  const handleAvatarSelect = useCallback(
    (id: string) => {
      const avatar = listAvatar.find(item => item.avatar_uuid === id) as ISelfAvatarsListItemProps

      setListAvatar(
        listAvatar.map(item => (item.id === avatar.id ? { ...item, active: true } : { ...item, active: false })),
      )

      updateModel({
        id: avatar.avatar_uuid,
        stage_image: avatar.image_url,
        type: 'SELF_MODEL',
        gender: avatar.gender,
      })
    },
    [listAvatar, updateModel],
  )

  const handleSendPhoto = useCallback(() => {
    navigate('InformData')
  }, [navigate])

  const handleGenderChange = (value: number) => {
    setSelectedGender(value)
    setComponentDisabled(true)
  }

  const handleSkinChange = (value: number) => {
    setSelectedSkin(value)
    setComponentDisabled(true)
  }

  const handleModelChange = useCallback(
    (value: string) => {
      handleModelSelect(modelsData.find(model => model.details.id === value) as IModel)
    },
    [handleModelSelect, modelsData],
  )

  const onDeleteAvatar = (id: string) => {
    const avatar = listAvatar.find(item => item.avatar_uuid === id) as ISelfAvatarsListItemProps

    const currentAvatar = listAvatar.find(item => item.active)

    deleteAvatar(avatar.id)

    Tracking.logEvent('AVATAR_DELETE', {
      avatar: avatar.avatar_uuid,
      type: 'self',
      widget: true,
    })

    if (!avatar.active) {
      setListAvatar(
        getAvatars().map(item =>
          item.id === currentAvatar?.id ? { ...item, active: true } : { ...item, active: false },
        ),
      )

      closeBackdrop()

      return
    }

    const prevAvatar = getAvatars()[0]

    setListAvatar(
      getAvatars().map(item => (item.id === prevAvatar.id ? { ...item, active: true } : { ...item, active: false })),
    )

    removeTryon({ setState: clearStates })

    if (prevAvatar) {
      updateModel({ id: prevAvatar.avatar_uuid, stage_image: prevAvatar.image_url, type: 'SELF_MODEL' }, false)
    } else {
      updateModel((getDefaultModels()?.male || getDefaultModels()?.female) as ICurrentModel, false)
    }

    closeBackdrop()
  }

  const openBackdrop = useCallback(
    (id: string) => {
      const avatar = listAvatar.find(item => item.avatar_uuid === id) as ISelfAvatarsListItemProps

      setAvatarDelete(avatar)
      setVisibleBackdrop(!visibleBackdrop)
    },
    [listAvatar, visibleBackdrop],
  )

  const closeBackdrop = () => setVisibleBackdrop(!visibleBackdrop)

  const onUseModelActive = useCallback(() => {
    fetchSkins()
    fetchGenders()
    setHasTabUseModelActive(true)
  }, [fetchGenders, fetchSkins])

  const getTabUseMyPhoto = useCallback(
    () =>
      listAvatar.length > 0 ? (
        <TabUseMyPhotoUpload
          listAvatar={listAvatar}
          openBackdrop={openBackdrop}
          onAvatarSelected={handleAvatarSelect}
        />
      ) : (
        <Styled.WrapperBoxContent>
          <InstructionalCard
            onClick={showCameraFlow ? handleSendPhoto : undefined}
            title={translate(showCameraFlow ? 'CARD_USE_MY_PHOTO_MOBILE_TITLE' : 'CARD_USE_MY_PHOTO_TITLE')}
            description={translate(
              showCameraFlow ? 'CARD_USE_MY_PHOTO_MOBILE_DESCRIPTION' : 'CARD_USE_MY_PHOTO_DESCRIPTION',
            )}
            slides={slides}
            borderRadius="16px"
            actionComponent={showCameraFlow ? undefined : <QRCode path={mobilePath} />}
          />
        </Styled.WrapperBoxContent>
      ),
    [handleAvatarSelect, handleSendPhoto, listAvatar, mobilePath, openBackdrop, showCameraFlow],
  )

  const getTabUseModel = useCallback(
    () => (
      <Styled.Row>
        <Styled.WrapperBoxContent>
          {gendersData.length > 1 && (
            <Styled.Column>
              <Box padding="20px" borderRadius="20px" backgroundColor={theme.colors.neutral.black100} height={'auto'}>
                <Styled.Row>
                  <Styled.Subtitle align="center" color={theme.colors.textPrimary}>
                    {translate('MODEL_GENDER_SELECT_TITLE')}
                  </Styled.Subtitle>
                  {!!selectedGender && (
                    <Select
                      name="gender"
                      onChange={handleGenderChange}
                      options={gendersData.map(gender => ({
                        label: translate(`MODEL_GENDER_SELECT_${gender.name}`),
                        value: gender.id,
                      }))}
                      value={selectedGender}
                      disabled={componentDisabled}
                    />
                  )}
                </Styled.Row>
              </Box>
            </Styled.Column>
          )}
          {skinsData.length > 3 && (
            <Styled.Column>
              <Box padding="20px" borderRadius="20px" backgroundColor={theme.colors.neutral.black100} height={'auto'}>
                <Styled.Row>
                  <Styled.Subtitle align="center" color={theme.colors.textPrimary}>
                    Escolha o tom de pele
                  </Styled.Subtitle>
                  {!!selectedSkin && (
                    <ColorsRadio
                      options={skinsData.map(skin => ({ color: skin.value, value: skin.id }))}
                      value={selectedSkin}
                      onChange={handleSkinChange}
                      disabled={componentDisabled}
                    />
                  )}
                </Styled.Row>
              </Box>
            </Styled.Column>
          )}
        </Styled.WrapperBoxContent>

        <Styled.ColumnCarousel hasSpace={skinsData.length > 3 || gendersData.length > 1}>
          <Box isLoading={modelsLoading} height="100%" testID="box-models-list">
            {!!modelsData.length && selectedModelIndex !== undefined && (
              <ModelsList
                data={modelsData.map(({ details }) => ({
                  id: details.id,
                  url: details.thumbnail,
                  active: selectedModel === details.id,
                }))}
                onClick={handleModelChange}
                initialIndex={selectedModelIndex}
              />
            )}
          </Box>
        </Styled.ColumnCarousel>
      </Styled.Row>
    ),
    [
      componentDisabled,
      gendersData,
      handleModelChange,
      modelsData,
      modelsLoading,
      selectedGender,
      selectedModel,
      selectedModelIndex,
      selectedSkin,
      skinsData,
    ],
  )

  const getTabHeaderData: () => Array<ITabDefaultHeaderProps & { children: string }> = useCallback(
    () => [
      {
        index: 0,
        active: tabActive === 0,
        onClick: (index: number) => setTabActive(index),
        children: translate('USE_MY_PHOTO_TAB'),
      },
      {
        index: 1,
        active: tabActive === 1,
        onClick: (index: number) => setTabActive(index),
        children: translate('USE_MODEL_TAB'),
      },
    ],
    [tabActive],
  )

  const getTabContentData: () => Array<ITabDefaultContentProps & { children: JSX.Element }> = useCallback(
    () => [
      {
        index: 0,
        active: tabActive === 0,
        children: getTabUseMyPhoto(),
      },
      {
        index: 1,
        active: tabActive === 1,
        children: getTabUseModel(),
        onActive: onUseModelActive,
        onInactive: () => setHasTabUseModelActive(false),
      },
    ],
    [getTabUseModel, getTabUseMyPhoto, onUseModelActive, tabActive],
  )

  const getTabHeader = useCallback(
    () => (
      <TabHorizontalList>
        {getTabHeaderData().map(({ children, ...rest }) => (
          <TabDefaultHeader key={rest.index} {...rest}>
            {children}
          </TabDefaultHeader>
        ))}
      </TabHorizontalList>
    ),
    [getTabHeaderData],
  )

  const getTabContent = useCallback(
    () => (
      <TabDefaultWrapper height="100%">
        {getTabContentData().map(({ children, ...rest }) => (
          <TabDefaultContent key={rest.index} {...rest}>
            {children}
          </TabDefaultContent>
        ))}
      </TabDefaultWrapper>
    ),
    [getTabContentData],
  )

  useEffect(() => {
    if (!hasTabUseModelActive || (!selectedSkin && !selectedGender)) return

    setModelsLoading(true)

    fetchModels({
      gender: gendersData.find(gender => gender.id === selectedGender)?.name,
      skin_tone_id: selectedSkin,
    })
  }, [selectedSkin, selectedGender, hasTabUseModelActive])

  useEffect(() => {
    if (!hasTabUseModelActive || !gendersData.length) return

    const currentGender = gendersData.find(gender => gender.name === getCurrentModel()?.gender) || gendersData[0]

    setSelectedGender(currentGender?.id)
  }, [gendersData, hasTabUseModelActive])

  useEffect(() => {
    if (!hasTabUseModelActive || skinsData.length <= 3) return

    const currentSkin = getCurrentModel()?.skin

    setSelectedSkin(currentSkin)
  }, [skinsData, hasTabUseModelActive])

  useEffect(() => {
    if (!hasTabUseModelActive || !modelsData.length) return

    setComponentDisabled(false)
    setModelsLoading(false)

    /* istanbul ignore else */
    if (!selectedModel) {
      const currentModel = getCurrentModel()
      const newModel = modelsData.find(({ details: model }, index) => {
        /* istanbul ignore else */
        if (model.id === currentModel?.id) {
          setSelectedModelIndex(index)
          return model
        }
      })

      const newModelId = newModel?.details?.id

      if (newModelId) {
        setSelectedModel(newModelId)
        return
      }

      setSelectedModelIndex(0)
    }
  }, [modelsData, hasTabUseModelActive])

  useEffect(() => {
    if (modelsData.length && selectedSkin && selectedModel) {
      const modelId = modelsData[selectedModelIndex as number]?.details.id
      setSelectedModel(modelId)
    }
  }, [modelsData, selectedSkin])

  useEffect(() => {
    if (tabActive === 1 || listAvatar.length || showCameraFlow) return

    startSession()
    startSubscription()
  }, [tabActive, listAvatar, showCameraFlow])

  useEffect(
    () => setTabActive(getCurrentModel().type === 'SELF_MODEL' || params?.modelsTab === 'SELF' ? 0 : 1),
    [getCurrentModel],
  )

  return (
    <Styled.Container data-testid="model-wrapper">
      <Backdrop
        visible={visibleBackdrop}
        testID="delete"
        withBackground
        title={translate('MODELS_BACKDROP_DELETE_PHOTO_MODAL_TITLE')}
      >
        <Styled.Footer>
          <TextButton variant="outlined" testID="keep-photo-button" onClick={closeBackdrop}>
            {translate('DELETE_PHOTO_KEEP_BUTTON')}
          </TextButton>

          <TextButton
            background={theme.colors.coralRed}
            testID="delete-photo-button"
            onClick={() => onDeleteAvatar(avatarDelete?.avatar_uuid as string)}
          >
            {translate('DELETE_PHOTO_DELETE_BUTTON')}
          </TextButton>
        </Styled.Footer>
      </Backdrop>

      <RoutesHeader title={translate('CHANGE_MODEL')} closeButtonUrl="Home" closeButtonIconName="arrowDown" />

      {tabActive !== undefined && (
        <Styled.Content>
          <Styled.WrapperHeader>{getTabHeader()}</Styled.WrapperHeader>
          <Styled.WrapperContent>{getTabContent()}</Styled.WrapperContent>
        </Styled.Content>
      )}
    </Styled.Container>
  )
}
