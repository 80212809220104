import { useCallback } from 'react'

import { useLazyQuery, useMutation } from '@apollo/client'

import { AxiosClient } from '~/clients/axios'

import {
  GET_MEASUREMENTS,
  IFetchParameters,
  IMutationData,
  IMutationVars,
  IQueryData,
  IQueryVars,
  ISendImage,
  ISendInfos,
  IUpdateParametersMeasurements,
  IUseQuerySizing,
  UPDATE_MEASUREMENTS,
} from '.'

export const useQuerySizing = (): IUseQuerySizing => {
  const timeoutInSeconds = 20

  const [fetchModelMeasurements] = useLazyQuery<IQueryData, IQueryVars>(GET_MEASUREMENTS, {
    fetchPolicy: 'network-only',
  })
  const [updateMeasurementsMutation] = useMutation<IMutationData, IMutationVars>(UPDATE_MEASUREMENTS)

  const updateMeasurements = useCallback(
    async ({
      payload,
      callbackOnError,
      callbackOnSuccess,
      callbackOnProcessing = () => ({}),
    }: IUpdateParametersMeasurements) => {
      try {
        callbackOnProcessing(true)

        await updateMeasurementsMutation({
          variables: {
            avatar_uuid: payload.avatar_uuid,
            set: {
              updated_height: payload.height,
              updated_measurements_chest: payload.chest,
              updated_measurements_hip: payload.hip,
              updated_measurements_waist: payload.waist,
            },
          },
        })

        callbackOnSuccess()
      } catch (error) {
        callbackOnError({
          name: error?.message === `Timeout.` ? 'PROCESSING_TIMEOUT' : 'PROCESSED_ERROR',
          message: `[ERROR][SIZING][UPDATE_MEASUREMENTS]: ${error?.message}`,
        })
      } finally {
        callbackOnProcessing(false)
      }
    },
    [updateMeasurementsMutation],
  )

  const sendImage = useCallback(
    async ({ payload, callbackOnError, callbackOnSuccess, callbackOnProcessing = () => ({}) }: ISendImage) => {
      try {
        callbackOnProcessing(true)

        const {
          data: { image_url },
        } = await AxiosClient().post<{ image_url: string }>('/sizing/attach-image', payload, {
          headers: { 'Content-Type': 'multipart/form-data' },
          timeout: timeoutInSeconds * 1000,
          timeoutErrorMessage: `Timeout.`,
        })

        callbackOnSuccess(image_url)
      } catch (error) {
        callbackOnError({
          name: error?.message === `Timeout.` ? 'PROCESSING_TIMEOUT' : 'PROCESSED_ERROR',
          message: `[ERROR][SIZING][SEND][${payload.get('type')}][IMAGE]: ${
            error?.response?.data?.message || error?.message
          }`,
        })
      } finally {
        callbackOnProcessing(false)
      }
    },
    [],
  )

  const sendInfos = useCallback(
    async ({ payload, callbackOnError, callbackOnSuccess, callbackOnProcessing = () => ({}) }: ISendInfos) => {
      try {
        callbackOnProcessing(true)

        await AxiosClient().post('/sizing/attach-data', payload, {
          timeout: timeoutInSeconds * 1000,
          timeoutErrorMessage: 'Timeout.',
        })

        callbackOnSuccess()
      } catch (error) {
        callbackOnError({
          name: error?.message === 'Timeout.' ? 'PROCESSING_TIMEOUT' : 'PROCESSED_ERROR',
          message: `[ERROR][SIZING][SEND][INFOS]: ${error?.response?.data?.message || error?.message}`,
        })
      } finally {
        callbackOnProcessing(false)
      }
    },
    [],
  )

  const fetchMeasurements = useCallback(
    async ({ payload, callbackOnSuccess, callbackOnError = () => ({}) }: IFetchParameters) => {
      const { data, error } = await fetchModelMeasurements({ variables: { avatar_uuid: payload.avatar_uuid } })

      if (error) {
        callbackOnError(error)
        return
      }

      callbackOnSuccess(data?.model_to_tryon.length ? data?.model_to_tryon : [])
    },
    [fetchModelMeasurements],
  )

  return {
    fetchMeasurements,
    sendImage,
    sendInfos,
    updateMeasurements,
  }
}
