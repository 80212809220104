import { useCallback, useEffect, useRef, useState } from 'react'

import { useSubscription } from '@apollo/client'

import {
  GET_MEASURES,
  IFetchParameters,
  IQueryData,
  IQueryVars,
  IUseQueryMeasurements,
  TMeasurementsCallbackOnError,
  TMeasurementsCallbackOnProcessing,
  TMeasurementsCallbackOnSuccess,
} from '.'

export const useQueryMeasurements = (): IUseQueryMeasurements => {
  const callbackSuccess = useRef<{ call: TMeasurementsCallbackOnSuccess }>()
  const callbackError = useRef<{ call: TMeasurementsCallbackOnError }>()
  const callbackProcessing = useRef<{ call: TMeasurementsCallbackOnProcessing }>()
  const timeoutKey = useRef<NodeJS.Timeout>()
  const [currentPayload, setCurrentPayload] = useState<IQueryVars>()

  const timeoutInSeconds = 5

  const { data, error, loading } = useSubscription<IQueryData, IQueryVars>(GET_MEASURES, {
    variables: {
      avatar_uuid: currentPayload?.avatar_uuid,
      products: currentPayload?.products,
    },
    skip: !currentPayload,
    fetchPolicy: 'no-cache',
  })

  const reset = useCallback(() => {
    setCurrentPayload(undefined)

    clearTimeout(timeoutKey.current as NodeJS.Timeout)
  }, [])

  const unsubscribe = useCallback(() => {
    reset()

    callbackProcessing.current?.call(false)
  }, [reset])

  const startTimer = useCallback(
    timerValue => {
      timeoutKey.current = setTimeout(() => {
        callbackError.current?.call(new Error('[ERROR][MEASUREMENTS]: Timeout.'))

        unsubscribe()
      }, timerValue)
    },
    [unsubscribe],
  )

  const fetch = useCallback(
    async ({ payload, callbackOnSuccess, callbackOnError, callbackOnProcessing = () => ({}) }: IFetchParameters) => {
      callbackSuccess.current = { call: callbackOnSuccess }
      callbackError.current = { call: callbackOnError }
      callbackProcessing.current = { call: callbackOnProcessing }

      if (!payload.products.length) {
        callbackError.current?.call(
          new Error(
            '[ERROR][MEASUREMENTS][PAYLOAD]: é necessário informar pelomenos 1 produto para obter recomendações.',
          ),
        )

        return
      }

      reset()

      callbackProcessing.current.call(true)

      startTimer(timeoutInSeconds * 1000)

      setTimeout(() => {
        setCurrentPayload(payload)
      }, 100)
    },
    [startTimer, reset],
  )

  useEffect(() => {
    if (loading || !currentPayload) return

    if (error) {
      callbackError.current?.call(error)

      unsubscribe()
    }

    if (data?.measurements.length) {
      callbackSuccess.current?.call(data?.measurements)

      unsubscribe()
    }
  }, [data, error, loading, unsubscribe, currentPayload])

  return {
    fetch,
  }
}
