import { useCallback, useEffect, useState } from 'react'

import { useCurrentModel } from '~/hooks'
import { IUpdateAvatarMeasurements, TAvatarInfos } from '~/hooks-queries/sizing'
import { useAvatar } from '~/hooks/useAvatar'
import { TUpdateAvatarMeasurementsStatus } from '~/hooks/useAvatar/types'
import { useMeasurements } from '~/hooks/useMeasurements'

import { useAvatarContext } from '~/context/Avatar'
import { TCurrentMeasurementsState, TMeasurement, useMeasurementsContext } from '~/context/Measurements'
import { ITryonProvider, useTryonContext } from '~/context/Tryon'

import { ICategoryItem, IProduct, TGender } from '~/entities'

import {
  IUseSubscriptionProcessingUpdateInfos,
  IUseSubscriptionProcessingUpdateInfosProps,
  TSubscriptionRequest,
} from './types'

export const useSubscriptionProcessingUpdateInfo = ({
  avatarInfosOriginal,
}: IUseSubscriptionProcessingUpdateInfosProps): IUseSubscriptionProcessingUpdateInfos => {
  const { getCurrentModel } = useCurrentModel()
  const {
    setMeasurementsState,
    stateMeasurements,
    setCurrentMeasurementsState,
    clearStates: clearMeasurementsState,
  } = useMeasurementsContext()

  const { setAvatarState, stateAvatar, clearStates } = useAvatarContext()
  const { stateCurrentTryon } = useTryonContext()
  const { getAvatarByUuid, sendAvatarInfos, updateAvatarMeasurements, updateAvatar, resetAvatar } = useAvatar()
  const { startMeasurements, createMeasurements, resetMeasurements, updateMeasurements } = useMeasurements()

  const [sendUpdateAvatarMeasurements, setSendUpdateAvatarMeasurements] = useState<TUpdateAvatarMeasurementsStatus>()

  const [infosProcessedStatus, setInfosProcessedStatus] = useState<
    IUseSubscriptionProcessingUpdateInfos['infosProcessedStatus']
  >({ called: false, isLoading: false, error: false })

  const subscription = useCallback(
    (data: Omit<IUpdateAvatarMeasurements, 'avatar_uuid'>, type: TSubscriptionRequest) => {
      setInfosProcessedStatus({ isLoading: true, called: true, payload: data, type })

      updateAvatarMeasurements({
        data: { values: { ...data, avatar_uuid: getCurrentModel().id }, type },
        setState: setSendUpdateAvatarMeasurements,
      })
    },
    [getCurrentModel, updateAvatarMeasurements],
  )

  useEffect(() => {
    if (sendUpdateAvatarMeasurements?.isLoading || !sendUpdateAvatarMeasurements?.called) return

    if (sendUpdateAvatarMeasurements?.error) {
      // eslint-disable-next-line no-console
      console.error(`[UPDATE_AVATAR_MEASUREMENTS]: ${sendUpdateAvatarMeasurements?.error.message}`)
      setInfosProcessedStatus(currentState => ({ ...currentState, error: true, isLoading: false }))
      return
    }

    const currentAvatar = getAvatarByUuid(sendUpdateAvatarMeasurements?.data?.avatar_uuid as string)

    const payload: TAvatarInfos = {
      avatar_uuid: currentAvatar?.avatar_uuid as string,
      height: (sendUpdateAvatarMeasurements?.data?.height ||
        avatarInfosOriginal?.find(item => item.name === 'height')?.value) as number,
      age: currentAvatar?.age as number,
      gender: currentAvatar?.gender as TGender,
      weight: currentAvatar?.weight as number,
      bottom_product_id: stateCurrentTryon?.products.bottom?.id,
      top_product_id: stateCurrentTryon?.products.top?.id,
      full_product_id: stateCurrentTryon?.products.full?.id,
    }

    sendAvatarInfos({ data: payload, setState: setAvatarState })

    updateAvatar(currentAvatar?.id as number, {
      height: (sendUpdateAvatarMeasurements?.data?.height ||
        avatarInfosOriginal?.find(item => item.name === 'height')?.value) as number,
    })
  }, [
    getAvatarByUuid,
    getCurrentModel,
    sendAvatarInfos,
    sendUpdateAvatarMeasurements,
    setAvatarState,
    stateCurrentTryon?.products.bottom?.id,
    stateCurrentTryon?.products.top?.id,
    stateCurrentTryon?.products.full?.id,
    updateAvatar,
    avatarInfosOriginal,
  ])

  useEffect(() => {
    if (stateAvatar?.isLoading || !stateAvatar?.called) return

    if (stateAvatar.errors?.update) {
      // eslint-disable-next-line no-console
      console.error(`[UPDATE_AVATAR_MEASUREMENTS]: ${stateAvatar.errors?.update?.message}`)

      setInfosProcessedStatus(currentState => ({ ...currentState, error: true, isLoading: false }))

      resetAvatar({ data: ['stateAvatar'], setState: clearStates })
      return
    }

    const products = stateCurrentTryon?.products as ITryonProvider & { [key: string]: IProduct | undefined }

    const productsList = Object.keys(products)
      .map(category => products[category]?.id)
      .filter(Number) as number[]

    startMeasurements({
      data: {
        avatar_uuid: getCurrentModel().id,
        products: productsList,
      },
      setState: setMeasurementsState,
    })

    resetAvatar({ data: ['stateAvatar'], setState: clearStates })
  }, [
    clearStates,
    getAvatarByUuid,
    getCurrentModel,
    resetAvatar,
    setMeasurementsState,
    startMeasurements,
    stateAvatar,
    stateCurrentTryon?.products,
    updateAvatar,
  ])

  useEffect(() => {
    if (stateMeasurements?.isLoading || !stateMeasurements?.called) return

    if (stateMeasurements?.error) {
      // eslint-disable-next-line no-console
      console.error(`[UPDATE_AVATAR_MEASUREMENTS]: ${stateMeasurements?.error.message}`)

      setInfosProcessedStatus(currentState => ({ ...currentState, error: true, isLoading: false }))

      resetMeasurements({ data: ['stateMeasurements'], setState: clearMeasurementsState })
      return
    }

    const currentTyron = stateCurrentTryon?.products as ITryonProvider & { [key: string]: IProduct | undefined }

    const products: TCurrentMeasurementsState['products'] & {
      [key: string]: TMeasurement & { id: number; category: ICategoryItem }
    } = {}

    Object.keys(currentTyron).forEach(category => {
      const product = currentTyron[category]
      const measurements = stateMeasurements.data
        ?.filter(item => item.product_id === product?.id)
        .map(item => ({
          ...item,
          has_stock: !!product?.product_options?.find(option => option?.partner_size?.name === item.label)?.has_stock,
          selected: false,
        }))

      if (!measurements?.length) return

      products[category] = {
        id: product?.id as number,
        category: product?.category as ICategoryItem,
        measurements,
      }
    })

    updateMeasurements({
      data: {
        avatar_uuid: getCurrentModel().id,
        products,
        configs: { type: 'notification' },
      },
      setState: setCurrentMeasurementsState,
    })

    resetMeasurements({ data: ['stateMeasurements'], setState: clearMeasurementsState })

    setInfosProcessedStatus(currentState => ({ ...currentState, isLoading: false }))
  }, [
    clearMeasurementsState,
    createMeasurements,
    getCurrentModel,
    resetMeasurements,
    setCurrentMeasurementsState,
    stateCurrentTryon?.products,
    stateMeasurements,
    updateMeasurements,
  ])

  const resetProcessingStatus = useCallback(() => {
    setInfosProcessedStatus({ isLoading: false, error: false, called: false })
  }, [])

  return {
    subscription,
    infosProcessedStatus,
    resetProcessingStatus,
  }
}
