import { gql } from '@apollo/client'

export const GET_PARTNER = gql`
  query partners($api_key: String!) {
    partner(where: { api_key: { _eq: $api_key } }) {
      email
      whatsapp
      watermark_url
      crawler_api_key
      size_chart_url
      cart_url
      upscale
      currency
      locale
    }
  }
`
