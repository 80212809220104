/* eslint-disable react-hooks/exhaustive-deps */
import compact from 'lodash/compact'
import isNil from 'lodash/isNil'
import omitBy from 'lodash/omitBy'

import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { useAlertSizing } from '~/hooks/useAlertSizing'
import { useAvatar } from '~/hooks/useAvatar'
import { useCurrentModel } from '~/hooks/useCurrentModel'
import { useMeasurements } from '~/hooks/useMeasurements'
import { MeasurementsStorageInstance } from '~/hooks/useMeasurements/storage'
import { usePartner } from '~/hooks/usePartner'
import { useTryOnFit } from '~/hooks/useTryOnFit'
import { useTryon } from '~/hooks/useTryon'
import { useUser } from '~/hooks/useUser'
import { useWalkthrough } from '~/hooks/useWalkthrough'

import { CombinedItemsProvider } from '~/context/CombinedItems'
import { useHistory } from '~/context/History'
import { useMeasurementsContext } from '~/context/Measurements'
import { useNavigation } from '~/context/Navigation'
import { useTermsContext } from '~/context/Terms'
import { useTryonContext } from '~/context/Tryon'
import { useUserContext } from '~/context/User'
import { useWidgetState } from '~/context/WidgetState'

import { NewsletterContainer } from '~/containers/NewsletterContainer'

import { AlertSizing } from '~/components/AlertSizing'
import { AppButtonClose } from '~/components/AppButtonClose'
import AsideActionsContainer from '~/components/AsideActionsContainer'
import { RecommendationBackdrop } from '~/components/Backdrop/RecommendationBackdrop'
import { BackdropSizing } from '~/components/BackdropSizing'
import BottomActionsContainer from '~/components/BottomActionsContainer'
import { CombinedItemsBackdrop } from '~/components/CombinedItemsBackdrop'
import { Icon } from '~/components/Icon'
import { IconButton } from '~/components/IconButton'
import ImagePreview from '~/components/ImagePreview'
import { LoadingDots } from '~/components/LoadingDots'
import { PriceTags } from '~/components/PriceTags'
import { PrivacyDisclaimerBackdrop } from '~/components/PrivacyDisclaimerBackdrop'
import { ProductsBackdrop } from '~/components/ProductsBackdrop'
import SettingsBackdrop from '~/components/SettingsBackdrop'

import theme from '~/theme'

import { migrateData } from '~/screens/InformData/utils/formDataStorage'
import { sortByTag } from '~/screens/SizingScreen/helpers'
import { FeatureToggle } from '~/utils/featureToggle'
import { MobileUtils } from '~/utils/mobileUtils'
import { PostHogUtil } from '~/utils/postHog'
import { getPerfectSize, getPerfectStock } from '~/utils/sizing'
import Tracking from '~/utils/tracking'

import { SizingTipModal } from './components/SizingTipModal'
import { Container, SettingsButtonWrapper, UpscaleTag } from './styles'
import { getModalVideoPath, setSizingInfoModalAsSkipped } from './utils'

const TERMS_SCREEN = false

export const Home = () => {
  const { createTryon, updateTryon, resetTryon, startTryonUpscale, finishTryonUpscale } = useTryon()
  const {
    stateTryon,
    setCurrentTryonState,
    stateCurrentTryon,
    clearStates: clearTryonState,
    stateTryonUpscale,
    setTryonUpscaleState,
  } = useTryonContext()
  const {
    setMeasurementsState,
    stateMeasurements,
    stateCurrentMeasurements,
    setCurrentMeasurementsState,
    clearStates: clearMeasurementsState,
  } = useMeasurementsContext()
  const { terms, acceptTerms } = useTermsContext()
  const { startMeasurements, createMeasurements, resetMeasurements, updateMeasurements } = useMeasurements()
  const walkthrough = useWalkthrough()
  const { navigate, params } = useNavigation()
  const { getCurrentModel } = useCurrentModel()
  const { getPartner, setPartner } = usePartner()
  const { hasAvatarMeasurements, userHasSelfAvatar } = useAvatar()
  const { checkAlertSizingModel, addModelToAlertSizingList } = useAlertSizing()
  const { setCurrentUserState, stateCurrentUser } = useUserContext()
  const { updateUser } = useUser()
  const {
    shouldCallTryonFit,
    firstInteraction,
    setFirstInteraction,
    isEnableFirstInteraction,
    setEnableFirstInteraction,
  } = useWidgetState()
  const { getCurrentItem } = useHistory()
  const { generateTryonFit } = useTryOnFit()

  const [isCombineVisible, setIsCombineVisible] = useState(false)
  const [isTakePictureModalVisible, setIsTakePictureModalVisible] = useState(false)
  const [sizingModalOpeningSource, setSizingModalOpeningSource] = useState(undefined)
  const [isCombinedItensVisible, setIsCombinedItensVisible] = useState(false)
  const [isSettingsVisible, setIsSettingsVisible] = useState(false)
  const [isAlertMeasurements, setIsAlertMeasurements] = useState(false)
  const [isAlertSizing, setShowAlertSizing] = useState(false)
  const [screenLoading, setScreenLoading] = useState(true)
  const [hasCrawler, setHasCrawler] = useState(false)
  const [hasSizeChart, setHasSizeChart] = useState(false)
  const [visibleButtons, setVisibleButtons] = useState(true)
  const [isRecommendationVisible, setIsRecommendationVisible] = useState(false)
  const [showRecommendationTooltip, setShowRecommendationTooltip] = useState(false)

  const alertFromCombination = useRef(false)
  const timeoutIdRecommendationTooltip = useRef()
  const shouldOpenCombine = useRef(false)

  const IS_NEWSLETTER_FLAG_ENABLED = PostHogUtil.isFlagEnabled('NEWSLETTER_CONTENT')

  const isRecommendationToggle = FeatureToggle.get({
    environmentVariable: 'REACT_APP_FEATURE_FLAGS',
    value: 'recommendation',
  })

  const currentModelInformation = getCurrentModel()
  const isPreModel = currentModelInformation.type === 'PRE_MODEL'

  const isRecommendationTooltip = useMemo(
    () =>
      isEnableFirstInteraction() &&
      firstInteraction &&
      stateCurrentUser.showRecommendationTooltip &&
      !isCombineVisible &&
      !isCombinedItensVisible &&
      !isSettingsVisible &&
      !screenLoading &&
      !isAlertMeasurements &&
      !isAlertSizing &&
      !isRecommendationVisible,
    [
      stateCurrentUser.showRecommendationTooltip,
      firstInteraction,
      isCombineVisible,
      isCombinedItensVisible,
      isSettingsVisible,
      screenLoading,
      isAlertMeasurements,
      isAlertSizing,
      isRecommendationVisible,
    ],
  )

  const disableSizingButton = useMemo(
    () =>
      !isPreModel &&
      !stateCurrentMeasurements?.products?.top &&
      !stateCurrentMeasurements?.products?.bottom &&
      !stateCurrentMeasurements?.products?.full &&
      stateCurrentMeasurements?.configs?.type !== 'inactive',
    [
      isPreModel,
      stateCurrentMeasurements?.products?.top,
      stateCurrentMeasurements?.products?.bottom,
      stateCurrentMeasurements?.products?.full,
      stateCurrentMeasurements?.configs?.type,
    ],
  )

  const getRecommendationTooltip = useCallback(() => {
    clearTimeout(timeoutIdRecommendationTooltip.current)

    timeoutIdRecommendationTooltip.current = setTimeout(() => {
      setShowRecommendationTooltip(
        !stateMeasurements?.isLoading && !stateCurrentMeasurements?.configs?.showTooltip && isRecommendationTooltip,
      )
    }, 1100)
  }, [stateMeasurements?.isLoading, stateCurrentMeasurements?.configs?.showTooltip, isRecommendationTooltip])

  const userInteraction = useCallback(() => {
    if (!isRecommendationToggle) return

    setFirstInteraction(true)
  }, [setFirstInteraction, isEnableFirstInteraction])

  const onRecommedantionTooltipVisible = useCallback(() => setEnableFirstInteraction(false), [])

  const onRecommedantionTooltipHidden = useCallback(() => {
    const interactions = stateCurrentUser.interactions + 1
    const values = { interactions, showRecommendationTooltip: interactions < 2 }

    updateUser({ values, setState: setCurrentUserState })
  }, [updateUser, setCurrentUserState, stateCurrentUser.interactions])

  const handleCombinedItensState = open => {
    setIsCombinedItensVisible(open)
  }

  const handleHangerClick = () => {
    const products = [
      stateCurrentTryon?.products?.top,
      stateCurrentTryon?.products?.bottom,
      stateCurrentTryon?.products?.full,
    ].filter(Boolean)

    const productsWithoutStock = products.filter(
      ({ product_options }) => !product_options || !product_options.filter(option => option.has_stock).length,
    )

    Tracking.logEvent('CART_OPEN', {
      outofstock: productsWithoutStock.length ? productsWithoutStock.map(({ identifier }) => identifier) : undefined,
      origin: getCurrentModel().type === 'SELF_MODEL' ? 'size' : 'stage',
      widget: true,
    })

    userInteraction()

    handleCombinedItensState(true)
  }

  const handleChangeModelClick = () => {
    Tracking.logEvent('AVATAR_GALLERY', { widget: true })

    userInteraction()

    navigate('Models')
  }

  const handleCombineButton = () => {
    Tracking.logEvent('COMBINE_GALLERY', { widget: true, start: shouldOpenCombine.current, origin: 'stage' })

    userInteraction()

    setIsCombineVisible(true)
    shouldOpenCombine.current = false
  }

  const handleWalkthrough = () => {
    setIsSettingsVisible(false)
    walkthrough.handleRestart()
  }

  const handleSettings = () => {
    Tracking.logEvent('OPTIONS', { widget: true })

    userInteraction()

    setIsSettingsVisible(true)
  }

  const redirectWithAnimation = to => {
    setVisibleButtons(false)

    setTimeout(() => navigate(to), 300)
  }

  const navigateToSizingScreen = () => {
    setVisibleButtons(false)

    const currentModelId = getCurrentModel().id

    const topOrFullProduct = stateCurrentMeasurements?.products?.top || stateCurrentMeasurements?.products?.full
    const bottomProduct = stateCurrentMeasurements?.products?.bottom || null

    Tracking.logEvent('SIZE_OPEN', {
      avatar: currentModelId,
      bot_perfect_size: bottomProduct && getPerfectSize(stateCurrentMeasurements, bottomProduct.category.type),
      bot_perfect_stock: bottomProduct && getPerfectStock(stateCurrentMeasurements, bottomProduct.category.type),
      bottom: bottomProduct ? stateCurrentTryon.products.bottom.identifier : null,
      origin: 'stage',
      return: true,
      top: topOrFullProduct
        ? stateCurrentTryon?.products?.top?.identifier ?? stateCurrentTryon?.products?.full?.identifier
        : null,
      top_perfect_size: topOrFullProduct && getPerfectSize(stateCurrentMeasurements, topOrFullProduct.category.type),
      top_perfect_stock: topOrFullProduct && getPerfectStock(stateCurrentMeasurements, topOrFullProduct.category.type),
      type_avatar: 'self',
      widget: true,
    })

    userInteraction()

    redirectWithAnimation('SizingScreen')
  }
  const handleMeasurementsAlertRedoPhotoButton = () => navigate('Tips')

  const handleMeasurementsAlertButtonLater = () => setIsAlertMeasurements(false)

  const handleMeasurementsAlertOpen = () => {
    setIsAlertMeasurements(true)
  }

  const handleAlertSizing = () => {
    addModelToAlertSizingList(getCurrentModel()?.id)
    setShowAlertSizing(false)

    if (alertFromCombination.current) {
      alertFromCombination.current = false

      setIsCombineVisible(true)
    }
  }

  const handleRecommendationClick = status => {
    if (!status) {
      setIsRecommendationVisible(false)

      return
    }

    Tracking.logEvent('RECOMMENDER_OPEN', {
      id_avatar: getCurrentModel().id,
      type_avatar: getCurrentModel().type === 'SELF_MODEL' ? 'self' : 'pre',
      sku_top: stateCurrentTryon?.products?.top?.identifier ?? stateCurrentTryon?.products?.full?.identifier,
      top_categ: stateCurrentTryon?.products?.top?.category?.name ?? stateCurrentTryon?.products?.full?.category?.name,
      sku_bottom: stateCurrentTryon?.products?.bottom?.identifier,
      bot_categ: stateCurrentTryon?.products?.bottom?.category?.name,
      widget: true,
    })

    if (stateCurrentUser.showRecommendationBackdrop) {
      setIsRecommendationVisible(true)

      return
    }

    redirectWithAnimation('Recommendation')
  }

  const handleRecommendationButtonClick = () => {
    Tracking.logEvent('RECOMMENDER_HELP', {
      widget: true,
    })

    navigate('Recommendation')
  }

  const handleMeasurements = async ({ products }) => {
    const { data: partner } = await getPartner()
    const currentModel = getCurrentModel()

    if (currentModel.type === 'PRE_MODEL') {
      updateMeasurements({
        data: {
          avatar_uuid: currentModel.id,
          configs: {
            showButton: true,
            type: 'notification',
          },
        },
        setState: setCurrentMeasurementsState,
      })

      return
    }

    if (!partner.size_chart_url) {
      if (checkAlertSizingModel(currentModel?.id)) return

      setTimeout(() => {
        if (isCombineVisible) {
          alertFromCombination.current = true

          setIsCombineVisible(false)
        }

        setShowAlertSizing(true)

        return
      }, 1000)
    }

    if (hasAvatarMeasurements(currentModel.id)) {
      startMeasurements({
        data: {
          avatar_uuid: currentModel.id,
          products: Object.keys(products)
            .map(category => products[category]?.id)
            .filter(Number),
        },
        setState: setMeasurementsState,
      })

      updateMeasurements({
        data: {
          avatar_uuid: currentModel.id,
          configs: {
            showButton: false,
          },
        },
        setState: setCurrentMeasurementsState,
      })

      return
    }

    updateMeasurements({
      data: {
        avatar_uuid: currentModel.id,
        configs: {
          showButton: true,
          type: 'inactive',
        },
      },
      setState: setCurrentMeasurementsState,
    })

    if (MeasurementsStorageInstance.get(currentModel.id)?.showAlert) {
      setTimeout(() => {
        handleMeasurementsAlertOpen()
        migrateData()
      }, 1000)

      MeasurementsStorageInstance.update(currentModel.id, { showAlert: false })
    }
  }

  const onTryonProcessed = useCallback(async () => {
    if (stateTryon?.isLoading || !stateTryon?.called) return

    if (stateTryon?.error?.name) {
      setScreenLoading(false)

      if (stateTryon?.data?.from === 'avatar') {
        resetTryon({ data: ['stateTryon', 'stateCurrentTryon'], setState: clearTryonState })

        return
      }

      if (stateTryon?.error?.name !== 'PROCESSING_TIMEOUT') {
        resetTryon({ data: ['stateTryon'], setState: clearTryonState })

        return
      }

      navigate('Timeout', { type: 'tryon' })

      return
    }

    createTryon({
      data: {
        model: getCurrentModel(),
        tryon: stateTryon?.data?.tryon,
        products: omitBy(stateTryon?.data?.products, isNil),
        from: stateTryon?.data?.from,
        isAutomix: stateTryon?.data?.isAutomix,
        isUnselect: stateTryon?.data?.isUnselect,
        baseProduct: stateTryon?.data?.baseProduct,
      },
      setState: setCurrentTryonState,
    })

    Tracking.logEvent('TRY_ON', {
      avatar: getCurrentModel().id,
      avatar_type: getCurrentModel().type === 'SELF_MODEL' ? 'self' : 'pre',
      top: stateTryon?.data?.products?.top?.identifier ?? stateTryon?.data?.products?.full?.identifier,
      top_categ: stateTryon?.data?.products?.top?.category.name ?? stateTryon?.data?.products?.full?.category.name,
      bottom: stateTryon?.data?.products?.bottom?.identifier,
      bot_categ: stateTryon?.data?.products?.bottom?.category.name,
      sku: [
        stateTryon?.data?.products?.top?.identifier,
        stateTryon?.data?.products?.bottom?.identifier,
        stateTryon?.data?.products?.full?.identifier,
      ],
      unselect: stateTryon?.data?.isUnselect,
      automix: stateTryon?.data?.isAutomix,
      start: stateTryon?.data?.from === 'start',
      widget: true,
    })

    await handleStartTryonUpscale(stateTryon?.data?.tryon?.id)

    resetTryon({ data: ['stateTryon'], setState: clearTryonState })

    handleMeasurements({ products: stateTryon?.data?.products })
  }, [stateTryon])

  const handleStartTryonUpscale = useCallback(
    async tryonId => {
      if (!tryonId) return

      const { data: partner } = await getPartner()
      if (partner?.upscale) {
        startTryonUpscale({
          tryonId,
          setState: setTryonUpscaleState,
        })
      }
    },
    [startTryonUpscale, setTryonUpscaleState, getPartner],
  )

  const onTryonUpscaleProcessed = useCallback(async () => {
    if (!stateTryonUpscale?.data || !stateCurrentTryon) return

    const isSameTryon = stateCurrentTryon?.tryonId === stateTryonUpscale?.data?.tryonId
    if (!stateTryonUpscale?.called || !isSameTryon || stateCurrentTryon?.upscaledImageUrl) return

    updateTryon({
      data: stateTryonUpscale?.data,
      setState: setCurrentTryonState,
    })

    const currentTryon = getCurrentItem()
    const currentModel = getCurrentModel()
    Tracking.logEvent('TRY_ON_UPSCALE', {
      avatar: currentModel.id,
      avatar_type: currentModel.type === 'SELF_MODEL' ? 'self' : 'pre',
      top: currentTryon.top?.identifier ?? currentTryon.full?.identifier,
      top_categ: currentTryon.top?.category.name ?? currentTryon.full?.category.name,
      bottom: currentTryon.bottom?.identifier,
      bot_categ: currentTryon.bottom?.category.name,
      widget: true,
    })
  }, [stateTryonUpscale, stateCurrentTryon])

  const onMeasurementsProcessed = useCallback(() => {
    if (!stateMeasurements?.called || stateMeasurements?.isLoading) return

    if (stateMeasurements?.error) {
      resetMeasurements({ data: ['stateMeasurements', 'stateCurrentMeasurements'], setState: clearMeasurementsState })

      return
    }

    createMeasurements({
      data: {
        avatar_uuid: getCurrentModel().id,
        measurements: stateMeasurements.data,
        products: omitBy(stateCurrentTryon.products, isNil),
      },
      setState: setCurrentMeasurementsState,
    })

    resetMeasurements({ data: ['stateMeasurements'], setState: clearMeasurementsState })
  }, [stateMeasurements])

  const onGenerateTryonFit = useCallback(async () => {
    if (!disableSizingButton && shouldCallTryonFit) {
      const selectedProducts =
        stateCurrentMeasurements && stateCurrentMeasurements.products && Object.keys(stateCurrentMeasurements.products)
      const productsSizingInfos = () => {
        const products = {}
        selectedProducts?.forEach(category => {
          const product = stateCurrentMeasurements?.products[category]
          products[category] = product?.measurements.map(item => ({
            id: item.id,
            size: item.label,
            inStock: item.has_stock,
            message: item.fit_summary,
            sizingFitWeight: item.sizing_fit_weight,
            options: sortByTag([
              { bodyPart: 'hip', type: item.hip },
              { bodyPart: 'chest', type: item.chest },
              { bodyPart: 'waist', type: item.waist },
            ]).filter(option => option.type),
          }))
        })
        return products
      }
      const getActiveSizingData = compact(
        selectedProducts?.map(product => {
          const measurements = stateCurrentMeasurements.products[product]?.measurements
          const hasMeasurementSelected = measurements?.find(measurement => measurement.selected)
          const getMeasurementFit = measurements?.find(measurement => measurement.fit_summary === 'FIT')
          const getMeasurementFitId = getMeasurementFit?.id
          const getMeasurementFitSize = getMeasurementFit?.label

          return !hasMeasurementSelected
            ? {
                product,
                id: getMeasurementFitId,
                size: getMeasurementFitSize,
              }
            : null
        }),
      )[0]

      getActiveSizingData &&
        (await generateTryonFit({
          activeCategoryTab: getActiveSizingData?.product,
          sizingSuggestionActiveId: getActiveSizingData?.id,
          selectedSizes: getActiveSizingData?.size,
          allSizes: productsSizingInfos(),
          from: 'HomeScreen',
          initialFit: true,
        }))
    }
  }, [disableSizingButton, shouldCallTryonFit])

  useEffect(() => {
    const fetchPartner = async () => {
      const { data, status, message } = await getPartner()

      if (!status) {
        // eslint-disable-next-line no-console
        console.error(message)

        return
      }

      setPartner({ partner: data })
      setHasCrawler(!!data.crawler_api_key)
      setHasSizeChart(!!data.size_chart_url)
    }

    if (!TERMS_SCREEN && !terms) {
      acceptTerms()
      Tracking.logEvent('OPEN_PRIVACY_OR_TERMS', { widget: true, forced: true })
      Tracking.logEvent('AGREED_PRIVACY_OR_TERMS', { widget: true, forced: true })
    }

    fetchPartner()

    if (getCurrentModel().type === 'SELF_MODEL' && params?.measurements?.init) {
      handleMeasurements({ products: params?.measurements?.products })
    }

    if (params?.states?.homeCombinedBackdrop) {
      handleCombinedItensState(true)

      return
    }

    if (params?.states?.homeProductsBackdrop) {
      setIsCombineVisible(true)
      handleStartTryonUpscale(stateCurrentTryon?.tryonId)
      return
    }
  }, [])

  useEffect(onTryonProcessed, [onTryonProcessed])

  useEffect(onTryonUpscaleProcessed, [onTryonUpscaleProcessed])

  useEffect(onMeasurementsProcessed, [onMeasurementsProcessed])

  useEffect(getRecommendationTooltip, [getRecommendationTooltip])

  useEffect(onGenerateTryonFit, [onGenerateTryonFit])

  useEffect(() => {
    return () => {
      if (!showRecommendationTooltip) return

      onRecommedantionTooltipHidden()
    }
  }, [showRecommendationTooltip])

  useEffect(() => {
    if (stateTryon?.isLoading) setScreenLoading(true)
  }, [stateTryon?.isLoading])

  const onSizingButtonClick = useCallback(() => {
    const measurementsType = stateCurrentMeasurements?.configs?.type

    if (measurementsType === 'inactive') {
      handleMeasurementsAlertOpen()
      return
    }

    if (
      (isPreModel || !userHasSelfAvatar()) &&
      (measurementsType !== 'notification' || measurementsType !== 'checked')
    ) {
      openSizingInfoModal({ source: 'size_open' })
      return
    }

    navigateToSizingScreen()
  }, [stateCurrentMeasurements, isPreModel])

  const openSizingInfoModal = ({ source }) => {
    setSizingModalOpeningSource(source)
    Tracking.logEvent('TOOLTIP', {
      origin: source,
      action: 'open',
      widget: true,
    })

    setIsTakePictureModalVisible(true)
  }

  const handleSkipSizingInfoModal = useCallback(() => {
    Tracking.logEvent('TOOLTIP', {
      origin: sizingModalOpeningSource,
      action: 'close',
      widget: true,
    })
    setIsTakePictureModalVisible(false)
    setSizingInfoModalAsSkipped()
  }, [sizingModalOpeningSource])

  const onModalPrimaryButtonClick = () => {
    setSizingInfoModalAsSkipped()

    if (MobileUtils.isMobile()) {
      navigate('InformData')
      return
    }

    navigate('Models', { modelsTab: 'SELF' })
  }

  const handleImageLoad = useCallback(() => {
    !stateTryon?.isLoading && setScreenLoading(false)

    if (stateCurrentTryon?.upscaledImageUrl && stateTryonUpscale?.isLoading) {
      finishTryonUpscale()
    }
  }, [stateCurrentTryon, stateTryonUpscale, stateTryon, finishTryonUpscale, setScreenLoading])

  const handleImageError = useCallback(() => {
    setScreenLoading(false)
    finishTryonUpscale()
  }, [finishTryonUpscale, setScreenLoading])

  const onOpenNewsletter = useCallback(() => {
    setIsCombineVisible(false)
  }, [setIsCombinedItensVisible])

  return (
    <Container data-testid="home-screen">
      <AppButtonClose />
      <SettingsButtonWrapper data-testid="settings-button-wrapper">
        <IconButton
          backgroundColor={theme.colors.white}
          testID="settings-widget-button"
          onClick={handleSettings}
          borderColor={theme.colors.primary}
          icon={<Icon name="dots" size="20px" color={theme.colors.primary} />}
          boxShadow
        />
      </SettingsButtonWrapper>
      {(stateCurrentTryon || getCurrentModel()) && (
        <>
          {!screenLoading && <PriceTags hasCrawler={hasCrawler} onHangerClick={handleHangerClick} />}
          <ImagePreview
            fullsize
            url={stateCurrentTryon?.upscaledImageUrl || stateCurrentTryon?.imageUrl || getCurrentModel()?.stage_image}
            onLoaded={handleImageLoad}
            onError={handleImageError}
          />
        </>
      )}
      <LoadingDots
        isVisible={screenLoading}
        backgroundColor="rgba(0, 0, 0, 0.35)"
        color={theme.colors.white}
        size="12px"
      />
      <AsideActionsContainer
        combinedItemsCount={
          Object.keys(stateCurrentTryon?.products || {}).filter(category => stateCurrentTryon?.products[category])
            .length
        }
        onHangerClick={handleHangerClick}
        showSizingButton={hasSizeChart}
        sizingInfoConfigs={{
          ...stateCurrentMeasurements?.configs,
          type:
            stateCurrentMeasurements?.configs?.type === 'checked'
              ? 'checked'
              : isPreModel
              ? 'notification'
              : stateCurrentMeasurements?.configs?.type,
          showButton: !disableSizingButton,
          showTooltip:
            stateCurrentMeasurements?.configs?.showTooltip &&
            !isCombineVisible &&
            !isCombinedItensVisible &&
            !isSettingsVisible,
          onClick: onSizingButtonClick,
        }}
        visibleButtons={visibleButtons}
      />
      <BottomActionsContainer
        onCombineClick={handleCombineButton}
        onChangeModelClick={handleChangeModelClick}
        onRecommendationClick={handleRecommendationClick}
        walkthrough={walkthrough}
        visibleButtons={visibleButtons}
        setScreenLoading={setScreenLoading}
        showRecommendationTooltip={showRecommendationTooltip}
        onRecommedantionTooltipHidden={onRecommedantionTooltipHidden}
        onRecommedantionTooltipVisible={onRecommedantionTooltipVisible}
      />
      <CombinedItemsProvider>
        <CombinedItemsBackdrop
          active={isCombinedItensVisible}
          handleClose={() => handleCombinedItensState(false)}
          products={{
            top: stateCurrentTryon?.products?.top?.id,
            bottom: stateCurrentTryon?.products?.bottom?.id,
            full: stateCurrentTryon?.products?.full?.id,
          }}
        />
      </CombinedItemsProvider>
      <ProductsBackdrop active={isCombineVisible} handleState={setIsCombineVisible} isLoading={screenLoading} />
      <AlertSizing visible={isAlertSizing} onClick={handleAlertSizing} />
      <SettingsBackdrop
        active={isSettingsVisible}
        handleState={setIsSettingsVisible}
        handleWalkthrough={handleWalkthrough}
      />
      <BackdropSizing
        visible={isAlertMeasurements}
        outlinedButtonClick={handleMeasurementsAlertButtonLater}
        primaryButtonClick={handleMeasurementsAlertRedoPhotoButton}
      />
      <RecommendationBackdrop
        visible={isRecommendationVisible}
        onClose={() => handleRecommendationClick(false)}
        onButtonClick={handleRecommendationButtonClick}
      />
      <SizingTipModal
        isVisible={isTakePictureModalVisible}
        primaryButtonClick={onModalPrimaryButtonClick}
        secondaryButtonClick={() => {
          setIsTakePictureModalVisible(false)
          handleSkipSizingInfoModal()
        }}
        videoUrl={getModalVideoPath({ gender: currentModelInformation.gender })}
      />

      <NewsletterContainer
        onClickSnackBar={onOpenNewsletter}
        blockingState={stateTryon?.isLoading || screenLoading || !IS_NEWSLETTER_FLAG_ENABLED}
      />

      <PrivacyDisclaimerBackdrop />

      {!screenLoading && stateTryonUpscale?.isLoading && <UpscaleTag />}
    </Container>
  )
}
