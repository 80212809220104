import { useRef } from 'react'

import { useTryOnFitQuery } from '~/hooks-queries/tryOnFit'
import { IGenerateTryonFitPayload } from '~/hooks-queries/tryOnFit/types'
import { useAvatar } from '~/hooks/useAvatar'
import { useCurrentModel } from '~/hooks/useCurrentModel'

import { useTryOnFitContext } from '~/context/TryOnFit'
import { TCurrentTryonState, useTryonContext } from '~/context/Tryon'

import { ITryOnFit } from '~/entities/TryOnFit'
import { TSizingInfos } from '~/screens/SizingScreen/types'

import { IGenerateTryonFitParams, IStartSubscriptionParams, IUseTryOnFit, TActiveCategoryTab } from './types'
import { getInitialItemByTab } from './utils'

export const useTryOnFit = (): IUseTryOnFit => {
  const { unsubscribe, subscribe, generateTryonFit: generateTryonFitReq, fetchCategoryToPartner } = useTryOnFitQuery()
  const { setIsLoading, setData } = useTryOnFitContext()
  const { stateCurrentTryon, setCurrentTryonState } = useTryonContext()
  const { getCurrentModel } = useCurrentModel()
  const { getAvatarByUuid } = useAvatar()

  const sizingSuggestionActiveIdRef = useRef<number>()
  const activeCategoryTabRef = useRef<TActiveCategoryTab>()

  const onProccessEnd = () => {
    unsubscribe()
    setIsLoading(false)
  }

  const onSuccessSubscriptionCallback = (data: ITryOnFit[] = []) => {
    // eslint-disable-next-line no-console
    console.info('onSuccessSubscriptionCallback', { data })
    setData(data)

    const tryonFit = data.find(
      fit =>
        fit[`sizing_suggestion_${activeCategoryTabRef.current as TActiveCategoryTab}_id`] ===
        sizingSuggestionActiveIdRef.current,
    )

    if (tryonFit) {
      setCurrentTryonState(
        current =>
          ({
            ...current,
            tryonId: tryonFit.id,
            imageUrl: tryonFit.image_url,
            upscaledImageUrl: tryonFit.image_url,
          } as TCurrentTryonState),
      )
    }

    onProccessEnd()
  }

  const onFailSubscriptionCallback = (error: unknown) => {
    // eslint-disable-next-line no-console
    console.error('onFailSubscriptionCallback', { error })
    onProccessEnd()
  }

  const startSubscription = ({
    selfModelId,
    topProductId,
    bottomProductId,
    fullProductId,
    suggestionIdSubscribeParams,
    tryonFitLimit,
  }: IStartSubscriptionParams): void => {
    subscribe({
      parameters: {
        where: {
          status: { _eq: 'PROCESSED' },
          model_to_tryon_id: { _eq: selfModelId },
          ...(topProductId && { top_product_id: { _eq: topProductId } }),
          ...(bottomProductId && { bottom_product_id: { _eq: bottomProductId } }),
          ...(fullProductId && { full_product_id: { _eq: fullProductId } }),

          ...suggestionIdSubscribeParams,
        },
      },
      onSuccess: onSuccessSubscriptionCallback,
      onError: onFailSubscriptionCallback,
      tryonFitLimit,
    })
  }

  const generateTryonFit = async ({
    activeCategoryTab,
    sizingSuggestionActiveId,
    initialFit,
    allSizes,
    selectedSizes,
    from,
  }: IGenerateTryonFitParams) => {
    sizingSuggestionActiveIdRef.current = sizingSuggestionActiveId
    activeCategoryTabRef.current = activeCategoryTab
    const shouldCallTryonFitRequest = from !== 'SizingScreen' || !initialFit

    const activeCategoryTabInvertedMap: { top: 'bottom'; bottom: 'top'; full: 'full' } = {
      top: 'bottom',
      bottom: 'top',
      full: 'full',
    }
    const activeCategoryInvertedSizes = allSizes[activeCategoryTabInvertedMap[activeCategoryTab]]
    const avatar_uuid = getCurrentModel().id

    const generateTryonFitPayload: IGenerateTryonFitPayload = {
      avatar_uuid,
      ...(stateCurrentTryon?.products.top && { outfit_top_uuid: stateCurrentTryon?.products.top.outfit_uuid }),
      ...(stateCurrentTryon?.products.bottom && { outfit_bottom_uuid: stateCurrentTryon?.products.bottom.outfit_uuid }),
      ...(stateCurrentTryon?.products.full && { outfit_full_uuid: stateCurrentTryon?.products.full.outfit_uuid }),
    }

    const suggestionIdSubscribeParams: {
      [key: string]: { _eq?: number } | { _is_null: boolean }
    } = {}

    if (
      (!initialFit && activeCategoryInvertedSizes) ||
      (stateCurrentTryon?.products.top && stateCurrentTryon?.products.bottom)
    ) {
      generateTryonFitPayload[`suggestion_${activeCategoryTabInvertedMap[activeCategoryTab]}_id`] = getInitialItemByTab(
        {
          sizes: activeCategoryInvertedSizes as TSizingInfos[],
          ...(selectedSizes && { selectedSize: selectedSizes[activeCategoryTabInvertedMap[activeCategoryTab]] }),
        },
      )?.id
    } else {
      generateTryonFitPayload[`suggestion_${activeCategoryTab}_id`] = sizingSuggestionActiveId

      suggestionIdSubscribeParams[`sizing_suggestion_${activeCategoryTab}_id`] = { _is_null: false }

      if (activeCategoryTab !== 'full' && Object.keys(allSizes)) {
        suggestionIdSubscribeParams[`sizing_suggestion_${activeCategoryTabInvertedMap[activeCategoryTab]}_id`] = {
          _is_null: true,
        }
      }
    }

    if (stateCurrentTryon?.products.top && stateCurrentTryon?.products.bottom) {
      suggestionIdSubscribeParams[`sizing_suggestion_${activeCategoryTabInvertedMap[activeCategoryTab]}_id`] = {
        _eq: getInitialItemByTab({
          sizes: activeCategoryInvertedSizes as TSizingInfos[],
          ...(selectedSizes && { selectedSize: selectedSizes[activeCategoryTabInvertedMap[activeCategoryTab]] }),
        })?.id,
      }

      suggestionIdSubscribeParams[`sizing_suggestion_${activeCategoryTab}_id`] = { _is_null: false }
    }

    const tryonFitLimit = allSizes[activeCategoryTab]?.filter(size => size.sizingFitWeight !== null).length as number

    setIsLoading(true)

    shouldCallTryonFitRequest && (await generateTryonFitReq(generateTryonFitPayload))

    startSubscription({
      selfModelId: (getAvatarByUuid(avatar_uuid)?.model_to_tryon_id || getAvatarByUuid(avatar_uuid)?.id) as number,
      topProductId: stateCurrentTryon?.products.top?.id,
      bottomProductId: stateCurrentTryon?.products.bottom?.id,
      fullProductId: stateCurrentTryon?.products.full?.id,
      tryonFitLimit,
      suggestionIdSubscribeParams,
    })
  }

  return {
    startSubscription,
    generateTryonFit,
    fetchCategoryToPartner,
  }
}
