import styled from 'styled-components'

import { Paragraph } from '~/components/Typography/styles'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 0 20px;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const WrapperBoxContent = styled.div`
  width: 100%;
  padding: 0 20px;
`

export const Column = styled.div`
  display: flex;
  width: 100%;
  margin: 10px 0;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
`

export const ColumnCarousel = styled(Column)<{ hasSpace: boolean }>`
  height: 100%;
  margin-top: ${({ hasSpace }) => (hasSpace ? '20px' : 0)};
`

export const Subtitle = styled(Paragraph)<{ align: string; color: string }>`
  margin-bottom: 12px;
`

export const ContainerModelsList = styled.div`
  width: 100%;
  max-width: 260px;
  margin: 0 auto;
`

export const WrapperHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
`

export const WrapperContent = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`

export const WrapperFooter = styled.div`
  display: flex;
  margin-top: auto;
  padding-top: 16px;
  padding-left: 20px;
  padding-right: 20px;
`

export const Footer = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
`
