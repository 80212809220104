import { QueryLazyOptions } from '@apollo/client'

import { TWhere } from '~/hooks-queries/categories/types'
import { IFetchQueryVars, IWhere } from '~/hooks-queries/products'

import { IGetQueryPropsDefault } from '~/components/ProductsBackdrop/types'
import { PRODUCTS_PER_PAGE } from '~/components/ProductsBackdrop/utils'

import { IProduct, TGender } from '~/entities'

export const categoriesFilterWhere = ({ gender }: { gender: TGender }): { where: TWhere } => ({
  where: {
    active: { _eq: true },
    placeholder: { _eq: false },
    _or: [{ gender: { _eq: gender } }, { gender: { _eq: 'UNISEX' } }],
  },
})

export const getQueryWhere = (
  currentProduct?: IProduct,
  idCategory?: number,
  relatedProductIds?: number[],
  gender?: string,
): IFetchQueryVars => {
  const where: IWhere = {
    category_id: { _eq: idCategory },
    active: { _eq: true },
    status: { _eq: 'PROCESSED' },
    placeholder: { _eq: false },
    _or: [{ gender: { _eq: gender } }, { gender: { _eq: 'UNISEX' } }],
  }

  if (currentProduct) {
    where._not = { id: { _in: [currentProduct.id, ...(relatedProductIds as unknown as number[])] } }
  }

  return { where }
}

export const getQueryPropsDefault = ({
  offset = 0,
  limit = PRODUCTS_PER_PAGE + 1,
  where,
}: IFetchQueryVars): QueryLazyOptions<IFetchQueryVars> => ({
  variables: {
    offset,
    limit,
    where,
    order_by: { identifier: 'asc' },
  },
})

export const getQueryPropsRelated = ({
  currentProduct,
  selectedCategory,
  gender,
  offset = 0,
  limit = PRODUCTS_PER_PAGE + 1,
}: IGetQueryPropsDefault): QueryLazyOptions<IFetchQueryVars> => ({
  variables: {
    offset,
    limit,
    product_with_related_where: {
      category_id: { _eq: selectedCategory?.id },
      active: { _eq: true },
      status: { _eq: 'PROCESSED' },
      placeholder: { _eq: false },
      _not: { id: { _in: [currentProduct.id] } },
      parent_identifier: currentProduct.parent_identifier ? { _eq: currentProduct.parent_identifier } : {},
      _or: [{ gender: { _eq: gender } }, { gender: { _eq: 'UNISEX' } }],
    },

    product_without_related_where: {
      category_id: { _eq: selectedCategory?.id },
      active: { _eq: true },
      status: { _eq: 'PROCESSED' },
      placeholder: { _eq: false },
      _and: [
        {
          _or: [{ gender: { _eq: gender } }, { gender: { _eq: 'UNISEX' } }],
        },
        {
          _or: [
            { parent_identifier: currentProduct.parent_identifier ? { _neq: currentProduct.parent_identifier } : {} },
            { parent_identifier: { _is_null: true } },
          ],
        },
      ],
    },

    order_by: {
      parent_identifier: 'asc_nulls_last',
      identifier: 'asc',
    },
  },
})
