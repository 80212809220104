import { useCallback, useEffect, useRef, useState } from 'react'

import { useAvatarSync } from '~/hooks-queries/avatarSync'
import { useAvatar } from '~/hooks/useAvatar'

import { useNavigation } from '~/context/Navigation'

import { Sentry } from '~/clients/sentry'
import { extendTimeInMinutes, getTimeDifference } from '~/utils/date'
import Tracking from '~/utils/tracking'

import { SYNCED_AVATARS_STORAGE_KEY, SYNC_SESSION_STORAGE_KEY, SYNC_SESSION_TIMEOUT_IN_MINUTES } from './constants'
import { ISession, ISetAvatarAsSyncedParams, ISuccesfullSynCallbackParams, IUseSyncSession } from './types'

export const useSyncSession = (): IUseSyncSession => {
  const [syncedAvatars, setSyncedAvatars] = useState<number[]>([])
  const sessionRef = useRef<ISession | null>(null)

  const { unsubscribe, subscribe } = useAvatarSync()
  const { route, navigate } = useNavigation()
  const { setAvatar } = useAvatar()

  const userStorage = localStorage.getItem('@doris:user')
  const userUUID = userStorage ? JSON.parse(userStorage).uuid : null

  const getSessionFromLocalStorage = (): ISession | null => {
    const existingSession = localStorage.getItem(SYNC_SESSION_STORAGE_KEY)

    if (!existingSession) return null

    return JSON.parse(existingSession)
  }

  const setSessionValue = (params: ISession) => {
    sessionRef.current = params
  }

  const shouldStartSubscription = (): boolean => {
    const haveSession = sessionRef.current || getSessionFromLocalStorage()
    const isValidRoute = route !== 'SplashScreen'

    return Boolean(haveSession && userUUID && isValidRoute)
  }

  const startSession = () => {
    const dateRightNow = new Date().toISOString()
    const currentSession = getSessionFromLocalStorage()
    const shouldRefreshTimeout =
      currentSession &&
      getTimeDifference({ date1: new Date(currentSession.end_date), date2: new Date(dateRightNow) }) <=
        SYNC_SESSION_TIMEOUT_IN_MINUTES

    if (shouldRefreshTimeout) {
      setSessionValue({
        start_date: currentSession.start_date,
        end_date: extendTimeInMinutes({ date: dateRightNow, minutes: 15 }),
      })

      localStorage.setItem(
        SYNC_SESSION_STORAGE_KEY,
        JSON.stringify({
          start_date: currentSession.start_date,
          end_date: extendTimeInMinutes({ date: dateRightNow, minutes: 15 }),
        }),
      )

      return
    }

    const endDate = extendTimeInMinutes({ date: dateRightNow, minutes: 15 })

    setSessionValue({
      start_date: dateRightNow,
      end_date: endDate,
    })

    localStorage.setItem(
      SYNC_SESSION_STORAGE_KEY,
      JSON.stringify({
        start_date: dateRightNow,
        end_date: endDate,
      }),
    )
  }

  const setAvatarAsSynced = ({ id }: ISetAvatarAsSyncedParams) => {
    const payload = [...syncedAvatars, id]
    localStorage.setItem(SYNCED_AVATARS_STORAGE_KEY, JSON.stringify(payload))
    setSyncedAvatars(payload)
  }

  const endSession = useCallback(() => {
    localStorage.removeItem(SYNC_SESSION_STORAGE_KEY)
    sessionRef.current = null
  }, [])

  const isSessionExpired = (date: string): boolean => {
    const parsedDate = new Date(date)
    const now = new Date()

    return now.getTime() - parsedDate.getTime() > 0
  }

  const succesfullSyncCallback = ({
    id,
    thumbnail,
    model_to_tryon: { id: model_to_tryon_id, avatar_uuid, weight, height, age, gender },
  }: ISuccesfullSynCallbackParams) => {
    setAvatarAsSynced({ id })
    endSession()
    unsubscribe()
    setAvatar({
      id: model_to_tryon_id,
      avatar_uuid,
      image_url: thumbnail,
      age,
      height,
      weight,
      gender,
      model_to_tryon_id,
    })

    Tracking.logEvent('AVATAR_SYNC', { widget: true })

    if (route === 'Models') {
      navigate('Home')
    }

    navigate('Models', { modelsTab: 'SELF' })
  }

  const onFailSyncCallback = () => {
    Sentry.captureException({
      errorName: 'AVATAR_SYNC_FAILED',
      errorMessage: 'AVATAR_SYNC_FAILED',
      filePath: 'src/hooks/useSyncSession/index.ts',
      functionName: 'onFailSyncCallback',
    })

    unsubscribe()
    endSession()
  }

  const startSubscription = () => {
    if (!sessionRef.current) return

    subscribe({
      parameters: {
        user_uuid: userUUID,
        start_date: sessionRef.current.start_date,
        end_date: sessionRef.current.end_date,
        excluded_avatars: syncedAvatars,
      },
      onSuccess: succesfullSyncCallback,
      onError: onFailSyncCallback,
    })
  }

  useEffect(() => {
    const existingSession = getSessionFromLocalStorage()
    const syncedAvatarsFromStorage = localStorage.getItem(SYNCED_AVATARS_STORAGE_KEY)

    if (existingSession && !isSessionExpired(existingSession.end_date)) {
      setSessionValue(existingSession)
    }

    if (existingSession && isSessionExpired(existingSession.end_date)) {
      endSession()
    }

    if (syncedAvatarsFromStorage) {
      const parsedAvatars = JSON.parse(syncedAvatarsFromStorage)
      setSyncedAvatars(parsedAvatars)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    startSession,
    shouldStartSubscription,
    startSubscription,
  }
}
