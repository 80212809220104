import { FC, useEffect, useState } from 'react'

import { translate } from '~/utils/translate'

import * as Styled from './styles'
import { INTERVAL_VALUE, defaultImages } from './constants'
import { IStartButtonProps } from './types'

export const StartButton: FC<IStartButtonProps> = ({
  onClick,
  images = defaultImages,
  showBadge,
  description,
  hasBreak,
}) => {
  const [alternateImage, setAlternateImage] = useState(1)

  useEffect(() => {
    const intervalKey = setInterval(() => {
      setAlternateImage(i => {
        if (i >= images.length) {
          return 1
        }
        return i + 1
      })
    }, INTERVAL_VALUE)

    return () => {
      clearInterval(intervalKey)
    }
  }, [images.length])

  return (
    <Styled.Container data-testid="start-button-container" hasBreak={hasBreak} showBadge={showBadge}>
      <Styled.Button onClick={onClick} data-testid="start-button">
        {showBadge && (
          <Styled.BadgeNew data-testid="start-button-badge-new">
            {translate('DORIS_TRIGGER_BADGE_NEW').toUpperCase()}
          </Styled.BadgeNew>
        )}
        <Styled.ImageWrapper>
          {images.map((image, index) => (
            <Styled.Image
              key={`image_${image}`}
              visible={alternateImage === index + 1}
              src={image}
              data-testid="start-button-image"
            />
          ))}
        </Styled.ImageWrapper>
        <Styled.TextContainer data-testid="start-button-text">
          <Styled.BigText>{translate('DORIS_TRIGGER_TEXT')}</Styled.BigText>
        </Styled.TextContainer>
      </Styled.Button>
      <Styled.Description data-testid="start-button-description">
        {description ? description : translate('DORIS_TRIGGER_DESCRIPTION')}
      </Styled.Description>
    </Styled.Container>
  )
}
