import { translate } from '~/utils/translate'

import { ISlides } from './types'

export const settings = {
  dots: false,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerMode: false,
  arrows: true,
  initialSlide: 0,
}

export const slides = [
  {
    img: 'https://doris-media-production.s3.sa-east-1.amazonaws.com/mix-static-assets/edit-my-measurements-torax-busto.png',
    title: translate('HOW_TO_MEASURE_CHEST'),
    text: translate('HOW_TO_MEASURE_CHEST_DESCRIPTION'),
  },
  {
    img: 'https://doris-media-production.s3.sa-east-1.amazonaws.com/mix-static-assets/edit-my-measurements-cintura.png',
    title: translate('HOW_TO_MEASURE_WAIST'),
    text: translate('HOW_TO_MEASURE_WAIST_DESCRIPTION'),
  },
  {
    img: 'https://doris-media-production.s3.sa-east-1.amazonaws.com/mix-static-assets/edit-my-measurements-quadril.png',
    title: translate('HOW_TO_MEASURE_HIP'),
    text: translate('HOW_TO_MEASURE_HIP_DESCRIPTION'),
  },
] as ISlides[]
