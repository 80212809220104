import { useCallback, useMemo } from 'react'

import { useLazyQuery } from '@apollo/client'

import { GET_MODELS, IModelsQueryData, IModelsQueryVars, IQueryModels, IQueryOptions, TFetch } from '.'
import { modelsFormatted } from './helper'

export const useQueryModels = (): IQueryModels => {
  const [fetch, { data, loading, called, error }] = useLazyQuery<IModelsQueryData, IModelsQueryVars>(GET_MODELS, {
    fetchPolicy: 'network-only',
  })

  const models = useMemo(() => modelsFormatted(data || { models: [] }), [data])

  const fetchModels = useCallback(
    (options: IQueryOptions): TFetch =>
      new Promise(async (resolve, reject) => {
        const variables: IModelsQueryVars = {}

        if (options.default_model) {
          variables.where = {
            ['default_model']: {
              _eq: options.default_model,
            },
          }
        }

        if (options.gender) {
          variables.where = {
            ...variables.where,
            ['pre_model']: {
              ...variables.where?.pre_model,
              model_to_tryon: {
                gender: {
                  _eq: options.gender,
                },
              },
            },
          }
        }

        if (options.skin_tone_id) {
          variables.where = {
            ...variables.where,
            ['pre_model']: {
              ...variables.where?.pre_model,
              skin_tone_id: {
                _eq: options.skin_tone_id,
              },
            },
          }
        }

        const { data, error } = await fetch({ variables })

        if (error) {
          reject(error)

          return
        }

        resolve(modelsFormatted((data || { models: [] }) as IModelsQueryData))
      }),
    [fetch],
  )

  return {
    fetchModels,
    data: models,
    called,
    loading,
    error,
  }
}
