import styled, { css } from 'styled-components'

import { IStateProp } from './Ornaments'
import { spin } from './animations'

interface IOrnamentProps {
  type: 'a' | 'b' | 'c'
}

const Wrapper = styled.div`
  position: relative;
  width: 44px;
  height: 44px;
  display: block;
`

const OrnamentContainer = styled.div<IStateProp>`
  display: block;
  position: absolute;
  top: -6px;
  left: -6px;
  width: 56px;
  height: 56px;
  transform-origin: center center;

  ${({ state }) =>
    state === 1 &&
    css`
      animation: ${spin} 1s ease;
    `};
`

const Text = styled.span`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
  text-transform: uppercase;
  display: block;
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.typography.default.fontSize};
`

const Button = styled.button<IStateProp>`
  position: relative;
  z-index: 1;
  width: 44px;
  height: 44px;
  border-radius: 44px;
  display: block;
  padding: 0;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;

  background: ${({ theme }) => theme.colors.primary};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

  svg {
    transition: all ease 0.2s;
    transform-style: preserve-3d;

    ${({ state }) =>
      (state === 2 || state === 3) &&
      css`
        transition-delay: 0.2s;
      `};
    ${({ state }) =>
      (state === 1 || state === 0) &&
      css`
        transform: rotateY(90deg);
        transition-delay: 0s;
      `};
  }

  span {
    transition: all ease 0.2s;
    transform: rotateY(90deg) translate(-50%, -50%);
    transform-style: preserve-3d;
    transform-origin: left center;

    ${({ state }) =>
      (state === 2 || state === 3) &&
      css`
        transform: rotateY(90deg) translate(-50%, -50%);
      `};
    ${({ state }) =>
      (state === 1 || state === 0) &&
      css`
        transition-delay: 0.2s;
        transform: rotateY(0deg) translate(-50%, -50%);
      `};
  }
`

const Ornament = styled.i<IOrnamentProps>`
  width: 49px;
  height: 49px;
  border-radius: 49px;
  display: block;
  position: absolute;
  z-index: 0;
  opacity: 0.5;

  ${({ type }) =>
    type === 'a' &&
    css`
      background-image: linear-gradient(51deg, #3366ff 25%, #8cfac7 100%);
      left: 7px;
      top: 2px;
    `};

  ${({ type }) =>
    type === 'b' &&
    css`
      background-image: linear-gradient(44deg, #ffb140 0%, #ff5445 26%, #fc2b82 60%, #8e40b7 100%);
      left: 0;
      top: 4px;
    `};

  ${({ type }) =>
    type === 'c' &&
    css`
      background-image: linear-gradient(223deg, #ff3282 0%, #ff31a8 51%, #f7b500 100%);
      left: 5px;
      top: 7px;
    `};
`

export { Wrapper, OrnamentContainer, Ornament, Button, Text }
