import compact from 'lodash/compact'

import { FC, useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react'

import { ICategoryToPartner } from '~/hooks-queries/tryOnFit/types'
import { useAvatar } from '~/hooks/useAvatar'
import { IListMeasurements } from '~/hooks/useAvatar/types'
import { useSubscriptionProcessingUpdateInfo } from '~/hooks/useAvatar/useSubscriptionProcessingUpdateInfo'
import { useCurrentModel } from '~/hooks/useCurrentModel'
import { useMeasurements } from '~/hooks/useMeasurements'
import { useTryOnFit } from '~/hooks/useTryOnFit'
import { ISelectedSizes } from '~/hooks/useTryOnFit/types'

import { TCurrentMeasurementsState, useMeasurementsContext } from '~/context/Measurements'
import { useNavigation } from '~/context/Navigation'
import { useTryOnFitContext } from '~/context/TryOnFit'
import { ITryonProvider, TCurrentTryonState, useTryonContext } from '~/context/Tryon'
import { useWidgetState } from '~/context/WidgetState'

import { SelectSizingBackdrop } from '~/components/Backdrop/SelectSizingBackdrop'
import { TListSizingOption } from '~/components/Backdrop/SelectSizingBackdrop/components/ListSizing/types'
import { TSliderSizingControl } from '~/components/Backdrop/SelectSizingBackdrop/components/SliderSizing/types'
import { settings as SelectSizingBackdropSettings } from '~/components/Backdrop/SelectSizingBackdrop/constants'
import { useForm } from '~/components/Backdrop/SelectSizingBackdrop/hooks/useForm'
import { IFormData } from '~/components/Backdrop/SelectSizingBackdrop/hooks/useForm/types'
import { TSelectSizingBackdropInnerContentTypes } from '~/components/Backdrop/SelectSizingBackdrop/types'
import ImagePreview from '~/components/ImagePreview'
import { LoadingDots } from '~/components/LoadingDots'
import { NewSizingTagContainer } from '~/components/NewSizingTagContainer'
import { Notification } from '~/components/Notification'
import { SizingTabButtons } from '~/components/SizingTabButtons'
import { IConfigButton, TActiveTabName } from '~/components/SizingTabButtons/types'

import { useNotification } from '~/modules/Sizing/useNotification'

import theme from '~/theme'

import { IProduct } from '~/entities'
import { BackdropExitSavingMeasurements } from '~/screens/SizingScreen/components/BackdropExitSavingMeasurements'
import { BackdropResetMeasurements } from '~/screens/SizingScreen/components/BackdropResetMeasurements'
import { getPerfectSize, getPerfectStock } from '~/utils/sizing'
import Tracking from '~/utils/tracking'
import { translate } from '~/utils/translate'

import * as Styled from './styles'
import { categoryKeyMap } from './constants'
import { getScreenTimeout, sortByTab, sortByTag, sortTabsByMeasurement } from './helpers'
import {
  IHandleClose,
  IHandleTab,
  TAlertType,
  TCategoryTabs,
  TGetInitialValuesReturn,
  TGetSizingInfosParameters,
  TImagePreview,
  TProductsSizingInfos,
  TReducerState,
  TSizeSelected,
  TSizingInfos,
  TSortCurrentTabs,
  TStateTabs,
} from './types'

const reducerState: TReducerState = (state, nextState) => ({ ...state, ...nextState })

export const SizingScreen: FC = () => {
  const { stateCurrentMeasurements, setCurrentMeasurementsState } = useMeasurementsContext()
  const { stateCurrentTryon, setCurrentTryonState } = useTryonContext()
  const { isLoading: isFitLoading, data: tryonFitData, setData: setTryonFitData } = useTryOnFitContext()

  const { updateMeasurements } = useMeasurements()
  const { getAvatarMeasurements } = useAvatar()
  const { getCurrentModel } = useCurrentModel()
  const { navigate, params } = useNavigation()
  const { generateTryonFit, fetchCategoryToPartner } = useTryOnFit()
  const { notify, notification, clear: clearNotification } = useNotification()
  const { shouldCallTryonFit, setShouldCallTryonFit } = useWidgetState()

  const [sizingInfos, setSizingInfos] = useState<Array<TSizingInfos>>([])
  const [avatarInfos, setAvatarInfos] = useState<IListMeasurements>()

  const categoriesToPartnerRef = useRef<ICategoryToPartner[]>([])
  const bestFitTryon = useRef<TCurrentTryonState>()
  const sizesSelectedRef = useRef<ISelectedSizes>()

  const {
    subscription: subscriptionUpdateAvatarInfos,
    infosProcessedStatus,
    resetProcessingStatus,
  } = useSubscriptionProcessingUpdateInfo({ avatarInfosOriginal: avatarInfos?.original })

  const { formChanged, formData, handleSelectChange, saveFormData, clearForm, enableFormSave } = useForm(avatarInfos)

  const [screenStates, setScreenStates] = useReducer(reducerState, {
    imagePreviewType: 'top',
    contentType: 'slider',
    innerContentType: 'default',
    alert: { showAlert: null },
    visible: false,
  })

  const sliderControl = useRef<TSliderSizingControl>()

  const productsSizingInfos: TProductsSizingInfos = useMemo(() => {
    const products: TProductsSizingInfos = {}

    Object.keys(stateCurrentMeasurements?.products as TCurrentMeasurementsState['products']).forEach(category => {
      const product = stateCurrentMeasurements?.products[category]

      products[category] = product?.measurements.map(item => ({
        id: item.id,
        size: item.label,
        inStock: item.has_stock,
        message: item.fit_summary,
        sizingFitWeight: item.sizing_fit_weight,
        options: sortByTag([
          { bodyPart: 'hip', type: item.hip },
          { bodyPart: 'chest', type: item.chest },
          { bodyPart: 'waist', type: item.waist },
        ]).filter(option => option.type),
      })) as TSizingInfos[]
    })

    return products
  }, [stateCurrentMeasurements])

  const sortCurrentTabs: TSortCurrentTabs = (categories, sizes) => {
    const categoriesKeys = Object.keys(categories)
    const selectedCategories =
      categoriesKeys.length > 1
        ? compact(
            categoriesKeys.map(category => {
              const measurementsWithStock = categories[category]?.measurements.filter(item => item.has_stock)
              const getOnlyCategoriesWithStock =
                measurementsWithStock && measurementsWithStock.length > 0 ? category : undefined
              return getOnlyCategoriesWithStock
            }),
          )
        : categoriesKeys
    const getCurrentTab = selectedCategories.filter(category => !sizes[category]?.size)
    const sortedTabs =
      getCurrentTab.length === 0 ? sortTabsByMeasurement(selectedCategories) : sortTabsByMeasurement(getCurrentTab)

    return sortedTabs
  }

  const activeSizingInfo = sizingInfos.filter(si => si.active)

  const getInitialValues = useCallback((): TGetInitialValuesReturn => {
    const paramsTab = params?.states?.measurementsTab
    const measurementsProducts = stateCurrentMeasurements?.products
    const selectedSizes: TSizeSelected = {
      top: {
        size: measurementsProducts?.top?.measurements?.find(item => item.selected)?.label,
      },
      bottom: {
        size: measurementsProducts?.bottom?.measurements?.find(item => item.selected)?.label,
      },
      full: {
        size: measurementsProducts?.full?.measurements?.find(item => item.selected)?.label,
      },
    }
    const tabs: TStateTabs = {
      name: paramsTab ?? 'TOP',
    }

    if (measurementsProducts) {
      const tabsNames = sortCurrentTabs(measurementsProducts, selectedSizes)

      tabs.name = paramsTab ?? tabsNames.name
      tabs.next = tabsNames.next
    }

    return { selectedSizes, tabs }
  }, [stateCurrentMeasurements, params?.states?.measurementsTab])

  const getSizingInfos = useCallback(
    ({ tab, selectedSizes }: TGetSizingInfosParameters): Array<TSizingInfos> => {
      const tabNameLower = tab.toLowerCase()
      const selectedSize = selectedSizes && selectedSizes[tabNameLower]?.size
      const sizingData = [...(productsSizingInfos[tabNameLower] as TSizingInfos[])].map(item => ({
        ...item,
        active: false,
      }))

      const initialItem =
        sizingData.find(item => selectedSize && selectedSize === item.size) ||
        sizingData.find(item => item.message === 'FIT') ||
        sizingData.find(item => item.message === 'SOME_TIGHT') ||
        sizingData[0]

      initialItem.active = true

      return sizingData
    },
    [productsSizingInfos],
  )

  const handleClose = async ({ isChangedForm, confirm }: IHandleClose = {}) => {
    if (isChangedForm) {
      setScreenStates({ alert: { showAlert: 'exit_save', closeTypeAlert: 'exit' } })
      return
    }

    setSizingInfos(current => current.map(item => ({ ...item, active: false })))

    setScreenStates({ imagePreviewType: 'top', visible: false })

    await getScreenTimeout('close')

    !confirm && setCurrentTryonState(bestFitTryon.current as TCurrentTryonState)

    navigate('Home', { states: { homeCombinedBackdrop: !!params?.states?.measurementsTab } })
  }

  const handleTab = ({ tab, nextTab, contentType, innerContentType, isFormChanged, confirm }: IHandleTab) => {
    const { tabs } = getInitialValues()
    /* istanbul ignore if: was ignored because the if is just to avoid selecting the same tab and we have no way to confirm it in the test */
    if (screenStates.activeTab === tab) return

    if (isFormChanged) {
      setScreenStates({ alert: { showAlert: 'exit_save', closeTypeAlert: 'tab', nextTab: tab } })

      return
    }

    !confirm && setCurrentTryonState(bestFitTryon.current as TCurrentTryonState)

    setScreenStates({
      activeTab: tab,
      nextTab: nextTab ?? tabs.next,
      contentType,
      innerContentType,
    })

    if (tab === 'MEASUREMENTS') {
      setSizingInfos(current => current.map(item => ({ ...item, active: false })))

      Tracking.logEvent('SIZE_AVATAR', { avatar: getCurrentModel().id, widget: true })

      return
    }

    const sizingData = getSizingInfos({ tab, selectedSizes: screenStates.sizeSelected })

    setScreenStates({ imagePreviewType: tab.toLowerCase() as TImagePreview })
    setSizingInfos(sizingData)

    sliderControl.current?.rerender(sizingData)

    const activeCategoryTab = categoryKeyMap[tab as TCategoryTabs]
    const sizingSuggestionActiveId = sizingData.find(size => size.active)?.id as number

    clearNotification()

    const isSizingFitCategory = categoriesToPartnerRef.current.find(
      ({ category }) => category.id === stateCurrentMeasurements?.products[activeCategoryTab]?.category.id,
    )?.sizing_fit

    if (!isSizingFitCategory) {
      setTryonFitData([])
      notify({ message: translate('SIZING_FIT_UNAVAILABLE') })
      return
    }

    generateTryonFit({
      activeCategoryTab,
      sizingSuggestionActiveId,
      allSizes: productsSizingInfos,
      selectedSizes: sizesSelectedRef.current as ISelectedSizes,
      initialFit: false,
    })
  }

  const setTryonFitBySize = (size: string) => {
    const sizeActive = sizingInfos.find(item => item.size === size)

    const tryonFit = tryonFitData.find(
      fit => fit[`sizing_suggestion_${categoryKeyMap[screenStates.activeTab as TCategoryTabs]}_id`] === sizeActive?.id,
    )

    if (!tryonFit?.image_url) {
      notify({ message: translate('SIZING_FIT_UNAVAILABLE') })

      return
    }

    clearNotification()

    setCurrentTryonState(
      current =>
        ({
          ...current,
          tryonId: tryonFit.id,
          imageUrl: tryonFit.image_url,
          upscaledImageUrl: tryonFit.image_url,
        } as TCurrentTryonState),
    )
  }

  const onSizeChange = (size: string) => {
    /* istanbul ignore if: was ignored because the if is just to avoid click on the same size button and we have no way to confirm it in the test */
    if (size === sizingInfos.find(item => item.active)?.size) return

    setSizingInfos(current => current.map(item => ({ ...item, active: item.size === size })))

    setTryonFitBySize(size)
  }

  const onSizeSelected = (size: string) => {
    const currentMeasurementsProducts = stateCurrentMeasurements?.products as TCurrentMeasurementsState['products']
    const currentTab = screenStates.activeTab as TActiveTabName
    const currentTabLower = currentTab.toLowerCase()
    const selected = { ...screenStates.sizeSelected, [currentTabLower]: { size } }
    const setCurrentNextTab = sortCurrentTabs(currentMeasurementsProducts, selected)
    const nextTab = screenStates.nextTab !== currentTab ? screenStates.nextTab : setCurrentNextTab.name
    const nextTabLower = nextTab?.toLowerCase() ?? ''
    const isLastItemSelected =
      Object.keys(selected).filter(category => selected[category]?.size).length ===
        Object.keys(currentMeasurementsProducts).length ||
      !productsSizingInfos[nextTabLower]?.filter(item => item.inStock).length ||
      currentTab === nextTab

    sizesSelectedRef.current = selected as unknown as ISelectedSizes

    const tryonProducts: ITryonProvider & { [key: string]: IProduct | undefined } = { ...stateCurrentTryon?.products }
    const skuIdentifier = tryonProducts[currentTabLower]?.identifier

    Tracking.logEvent('SIZE_SELECTED', {
      avatar: getCurrentModel().id,
      type: currentTabLower,
      sku: skuIdentifier,
      sku_perfect_size: getPerfectSize(stateCurrentMeasurements, currentTabLower),
      sku_perfect_stock: getPerfectStock(stateCurrentMeasurements, currentTabLower),
      sku_size_selected: sizingInfos.find(item => item.active)?.size,
      sku_size_tag: sizingInfos.find(item => item.active)?.message,
      widget: true,
    })

    updateMeasurements({
      data: {
        avatar_uuid: getCurrentModel().id,
        products: {
          [currentTabLower]: {
            ...stateCurrentMeasurements?.products[currentTabLower],
            measurements: stateCurrentMeasurements?.products[currentTabLower]?.measurements.map(item => ({
              ...item,
              selected: item.label === size,
            })),
          },
        },
        configs: { type: isLastItemSelected ? 'checked' : 'notification' },
      } as TCurrentMeasurementsState,
      setState: setCurrentMeasurementsState,
    })

    setScreenStates({ sizeSelected: selected })

    setTryonFitBySize(size)

    if (isLastItemSelected) {
      handleClose({ confirm: true })

      return
    }

    handleTab({
      tab: setCurrentNextTab.name,
      nextTab: setCurrentNextTab.next,
      contentType: 'slider',
      innerContentType: 'default',
      confirm: true,
    })
  }

  const screenInit: () => void = useCallback(async () => {
    const avatar_uuid = getCurrentModel().id
    const data = { avatar_uuid }
    const { selectedSizes, tabs } = getInitialValues()
    const sizingData = getSizingInfos({ tab: tabs.name, selectedSizes })

    bestFitTryon.current = stateCurrentTryon

    getAvatarMeasurements({
      data,
      callbackOnSuccess: measurements => setAvatarInfos(measurements),
    })

    setScreenStates({
      activeTab: tabs.name,
      nextTab: tabs.next,
      sizeSelected: selectedSizes,
    })

    await getScreenTimeout('open')

    setSizingInfos(sizingData)
    setScreenStates({
      imagePreviewType: tabs.name.toLowerCase() as TImagePreview,
      visible: true,
    })

    updateMeasurements({
      data: { avatar_uuid, configs: { showTooltip: false } },
      setState: setCurrentMeasurementsState,
    })

    const activeCategoryTab = categoryKeyMap[tabs.name as TCategoryTabs]
    const sizingSuggestionActive = sizingData.find(size => size.active)

    const { data: categoriesData } = await fetchCategoryToPartner()

    categoriesToPartnerRef.current = categoriesData?.category_to_partner || []

    const isSizingFitCategory = categoriesToPartnerRef.current.find(
      ({ category }) => category.id === stateCurrentMeasurements?.products[activeCategoryTab]?.category.id,
    )?.sizing_fit

    if (!isSizingFitCategory) {
      setTryonFitData([])
      notify({ message: translate('SIZING_FIT_UNAVAILABLE') })
      return
    }

    sizesSelectedRef.current = {
      ...screenStates.sizeSelected,
      [activeCategoryTab]: { size: sizingSuggestionActive?.size },
    } as ISelectedSizes

    await generateTryonFit({
      activeCategoryTab,
      sizingSuggestionActiveId: sizingSuggestionActive?.id as number,
      allSizes: productsSizingInfos,
      selectedSizes: sizesSelectedRef.current as ISelectedSizes,
      initialFit: shouldCallTryonFit,
      from: 'SizingScreen',
    })

    setShouldCallTryonFit(false)

    // Add this rule because we dont need re-render after screen init
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSliderSizingInit = (control: TSliderSizingControl) => {
    sliderControl.current = control
  }

  const handleShowAlert = (alertType: TAlertType) => setScreenStates({ alert: { showAlert: alertType } })

  useEffect(screenInit, [screenInit])

  useEffect(() => {
    if (infosProcessedStatus.isLoading || !infosProcessedStatus.called) return

    if (infosProcessedStatus.error) {
      enableFormSave(true)
      resetProcessingStatus()
      return
    }

    const current = infosProcessedStatus.payload as IFormData

    setAvatarInfos(
      currentState =>
        ({
          ...currentState,
          original: currentState?.original,
          current: (currentState?.current as TListSizingOption[]).map(option => ({
            ...option,
            value: infosProcessedStatus.type === 'reset' ? 0 : (current[option.name] as number),
          })),
        } as IListMeasurements),
    )

    if (infosProcessedStatus.type === 'update') {
      Tracking.logEvent('SIZE_AVATAR_CHANGE', {
        id_avatar: getCurrentModel().id,
        avatar_height: infosProcessedStatus.payload?.height,
        avatar_chest: infosProcessedStatus.payload?.chest,
        avatar_waist: infosProcessedStatus.payload?.waist,
        avatar_hip: infosProcessedStatus.payload?.hip,
        widget: true,
      })
    } else {
      Tracking.logEvent('SIZE_AVATAR_RESET', { id_avatar: getCurrentModel().id, widget: true })
    }

    clearForm()

    enableFormSave(false)

    setScreenStates({ sizeSelected: {} })
    resetProcessingStatus()
  }, [clearForm, enableFormSave, getCurrentModel, infosProcessedStatus, resetProcessingStatus])

  const onExitSave = async () => {
    clearForm()

    setScreenStates({ alert: { showAlert: null } })

    if (screenStates.alert.closeTypeAlert === 'exit') {
      handleClose()
      return
    }

    handleTab({
      tab: screenStates.alert.nextTab as TActiveTabName,
      contentType: 'slider',
      innerContentType: 'default',
    })
    enableFormSave(false)
  }

  const onReset = () => {
    const callback = () => subscriptionUpdateAvatarInfos({ height: null, hip: null, chest: null, waist: null }, 'reset')

    saveFormData(callback)

    setScreenStates({ alert: { showAlert: null } })
  }

  const handleSaveForm = () => {
    const callback = () => subscriptionUpdateAvatarInfos(formData, 'update')

    saveFormData(callback)
  }

  const handleInnerContentChange = (innerContentType: TSelectSizingBackdropInnerContentTypes) => {
    setScreenStates({ innerContentType })

    if (innerContentType === 'carousel') {
      Tracking.logEvent('SIZE_AVATAR_RULER', { id_avatar: getCurrentModel().id, widget: true })
    }
  }

  const renderImagePreview = () => {
    const activeTryon = tryonFitData.find(tryonFit => tryonFit.id === stateCurrentTryon?.tryonId)

    if (tryonFitData?.length && activeTryon) {
      return tryonFitData.map(tryonFit => {
        return (
          <Styled.ImageContainer
            data-testid={`tryonfit-image-${tryonFit.id}`}
            key={tryonFit.id}
            active={tryonFit.id === stateCurrentTryon?.tryonId}
            className={(tryonFit.id === stateCurrentTryon?.tryonId && 'active') || ''}
          >
            <ImagePreview fullsize url={tryonFit.image_url as string} />
          </Styled.ImageContainer>
        )
      })
    }

    return <ImagePreview fullsize url={stateCurrentTryon?.imageUrl as string} />
  }

  return (
    <Styled.Container data-testid="sizing-screen-container" imagePreview={screenStates.imagePreviewType}>
      <Notification show={!!notification} onClose={clearNotification}>
        {notification}
      </Notification>
      <BackdropResetMeasurements
        visibleBackdrop={screenStates.alert.showAlert === 'reset'}
        closeBackdrop={handleShowAlert}
        onClick={onReset}
      />
      <BackdropExitSavingMeasurements
        visibleBackdrop={screenStates.alert.showAlert === 'exit_save'}
        closeBackdrop={handleShowAlert}
        onClick={onExitSave}
      />

      {renderImagePreview()}

      {!!sizingInfos?.length && (
        <>
          {activeSizingInfo.length && (
            <Styled.SizingContainer>
              <NewSizingTagContainer
                data={activeSizingInfo[0].options}
                gender={getCurrentModel().gender}
                loading={isFitLoading}
              />
            </Styled.SizingContainer>
          )}

          <Styled.TabContainer
            bottom={
              screenStates.visible
                ? SelectSizingBackdropSettings[screenStates.contentType][screenStates.innerContentType]?.height
                : undefined
            }
          >
            <SizingTabButtons
              activeTabName={screenStates.activeTab}
              configButtons={sortByTab([
                ...(Object.keys(stateCurrentMeasurements?.products as TCurrentMeasurementsState['products']).map(
                  category => {
                    const product = stateCurrentMeasurements?.products[category]

                    return {
                      disabled: isFitLoading,
                      iconName: product?.category.name,
                      iconSize: 18,
                      tabType: product?.category.type,
                      testID: product?.category.type,
                      pin: {
                        iconName:
                          screenStates.sizeSelected && screenStates.sizeSelected[category]?.size
                            ? 'checked'
                            : undefined,
                        direction: 'LEFT',
                        status:
                          screenStates.sizeSelected && screenStates.sizeSelected[category]?.size ? 'SUCCESS' : 'DANGER',
                      },
                      onClick: () =>
                        handleTab({
                          tab: product?.category.type as TActiveTabName,
                          contentType: 'slider',
                          innerContentType: 'default',
                          isFormChanged: formChanged,
                        }),
                    }
                  },
                ) as IConfigButton[]),
                {
                  iconName: 'tape',
                  iconSize: 22,
                  tabType: 'MEASUREMENTS',
                  testID: 'MEASUREMENTS',
                  disabled: isFitLoading,
                  onClick: () => handleTab({ tab: 'MEASUREMENTS', contentType: 'list', innerContentType: 'default' }),
                },
              ])}
            />
          </Styled.TabContainer>

          <SelectSizingBackdrop
            onShowAlert={handleShowAlert}
            onSizeSelected={onSizeSelected}
            onSizeChange={onSizeChange}
            onInnerContentChange={innerContentType => handleInnerContentChange(innerContentType)}
            contentType={screenStates.contentType}
            avatarInfos={avatarInfos}
            sizingInfos={sizingInfos}
            visible={screenStates.visible}
            handleClose={() => handleClose({ isChangedForm: formChanged })}
            onSliderInit={handleSliderSizingInit}
            innerContentType={screenStates.innerContentType}
            onHandleSelectChange={handleSelectChange}
            onUpdateAvatarMeasurements={handleSaveForm}
            formData={formData}
            formChanged={formChanged}
            loading={isFitLoading}
          />
        </>
      )}
      <LoadingDots
        isVisible={infosProcessedStatus?.isLoading}
        backgroundColor="rgba(0, 0, 0, 0.35)"
        color={theme.colors.white}
        size="12px"
      />
    </Styled.Container>
  )
}
