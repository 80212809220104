import { gql } from '@apollo/client'

export const GET_MODELS = gql`
  query models($where: pre_model_to_partner_bool_exp) {
    models: pre_model_to_partner(where: $where, order_by: { order_model: asc }) {
      order: order_model
      default: default_model
      details: pre_model {
        id
        skin_tone_id
        stage_image
        thumbnail
        model_to_tryon {
          avatar_uuid
          gender
        }
      }
    }
  }
`
