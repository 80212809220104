import * as i18n from '~/libs/languages'

import { DORIS_CONFIG_STORAGE_KEY, LOCAL_TRANSLATION_STORAGE_KEY, languages } from './constants'
import { TLanguageKeys, TRegionalLanguage, TTranslateKey } from './types'

export const validateAndParseLanguage = (languageCode: string): TRegionalLanguage => {
  if (languageCode.includes('pt')) return 'pt'

  if (languageCode.includes('es')) return 'es'

  return 'en'
}

export const getSimplifiedLanguageCode = (): TRegionalLanguage => {
  const storageConfig = localStorage.getItem(DORIS_CONFIG_STORAGE_KEY)

  if (!storageConfig) {
    return 'en'
  }

  const { language } = JSON.parse(storageConfig)

  return languages[language as TLanguageKeys]
}

export const translate = (key: TTranslateKey) => {
  const lang = getSimplifiedLanguageCode()

  if (lang?.includes('pt')) {
    return i18n.ptBr[key]
  }

  if (lang?.includes('es')) {
    return i18n.esEs[key]
  }

  return i18n.enUs[key]
}

export const translateFromStorage = (key: string) => {
  const languageCode = getSimplifiedLanguageCode()

  const storage = localStorage.getItem(LOCAL_TRANSLATION_STORAGE_KEY)

  if (!storage) {
    // eslint-disable-next-line no-console
    console.warn('[DORIS][i18n] NO TRANSLATION FOUND IN LOCALSTORAGE')
    return key
  }

  const translationSource = JSON.parse(storage)
  const translation = translationSource[languageCode]?.[key]

  if (!translation) {
    // eslint-disable-next-line no-console
    console.warn(`[DORIS][i18n] TRANSLATION FOR "${key}" NOT FOUND`)
    return key
  }

  return translation
}
