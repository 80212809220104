import { FC, useEffect, useRef, useState } from 'react'

import { useCurrentModel } from '~/hooks'
import { useQueryCategories, useQueryProducts } from '~/hooks-queries'
import { TTypeQuery } from '~/hooks-queries/products'
import { useMeasurements } from '~/hooks/useMeasurements'
import { usePartner } from '~/hooks/usePartner'
import { useTryon } from '~/hooks/useTryon'
import { TStartTryon } from '~/hooks/useTryon/types'

import { useMeasurementsContext } from '~/context/Measurements'
import { useTryonContext } from '~/context/Tryon'
import { useWidgetState } from '~/context/WidgetState'

import { Backdrop } from '~/components/Backdrop'
import CategoryList from '~/components/CategoryList'
import { Icon } from '~/components/Icon'
import { IconButton } from '~/components/IconButton'
import { ProductsList } from '~/components/ProductsList'

import theme from '~/theme'

import { ICategoryItem, IProduct, TCategoryType, TGender } from '~/entities'
import { getProduct as getBaseProduct } from '~/utils/baseProduct'
import Tracking from '~/utils/tracking'
import { translate } from '~/utils/translate'

import * as Styled from './styles'
import { categoriesFilterWhere, getQueryPropsDefault, getQueryPropsRelated, getQueryWhere } from './buildWhereFilter'
import { ILastTryon, IProductsBackdropProps, TCreateTryon, TCurrentProductsMap } from './types'
import { PRODUCTS_PER_PAGE, getSortProduct, uniqueProducts } from './utils'

export const ProductsBackdrop: FC<IProductsBackdropProps> = ({ active, handleState, isLoading }) => {
  const { getCurrentModel } = useCurrentModel()

  const { data: categoriesData, loading: loadingCategories } = useQueryCategories(
    categoriesFilterWhere({ gender: getCurrentModel().gender as TGender }),
  )
  const { stateTryon, stateCurrentTryon, setTryonState, clearStates: clearTryonStates } = useTryonContext()
  const { startTryon, removeTryon, finishTryonUpscale } = useTryon()
  const { clearStates: clearMeasurementsStates } = useMeasurementsContext()
  const { resetMeasurements } = useMeasurements()
  const { getPartner } = usePartner()
  const { shouldCallTryonFit, setShouldCallTryonFit } = useWidgetState()

  const [paginationLoading, setPaginationLoading] = useState(false)
  const [componentDisabled, setComponentDisabled] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState<ICategoryItem>()
  const [categoryListData, setCategoryListData] = useState<ICategoryItem[]>()
  const [lastTryon, setLastTryon] = useState<ILastTryon>()
  const [productsTemporary, setProductsTemporary] = useState<IProduct[]>()
  const [productsListData, setProductsListData] = useState<IProduct[]>()
  const [typeQueryProduct, setTypeQueryProduct] = useState<TTypeQuery>('default')
  const [notifyNetwork, setNotifyNetwork] = useState(false)
  const [productListTemporaryIsLastPage, setProductListTemporaryIsLastPage] = useState(false)
  const [productListCurrentPage, setProductsListCurrentPage] = useState(1)
  const [productListIsLastPage, setProductsListIsLastPage] = useState(false)
  const [nextProductActive, setNextProductActive] = useState<IProduct>()
  const [minimized, setMinimized] = useState(false)
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)

  const {
    fetch: fetchProductsData,
    data: productsData,
    dataWithRelated: productsDataRelated,
    dataWithoutRelated: productsDataWithoutRelated,
    loading: loadingProductsData,
    fetchMore: fetchMoreProductsData,
  } = useQueryProducts({
    notifyOnNetworkStatusChange: notifyNetwork,
    typeQuery: typeQueryProduct,
    fetchPolicy: 'network-only',
  })

  const currentModelId = getCurrentModel()?.id
  const gender = getCurrentModel()?.gender

  const currentProductsMap: TCurrentProductsMap = { ...stateCurrentTryon?.products }
  const categoryType = selectedCategory?.type.toLowerCase() as string
  const currentProductActive = currentProductsMap?.[categoryType]

  const getWhere = (currentProduct?: IProduct) => {
    const listProductsRelatedAndWithout = [...productsDataRelated, ...productsDataWithoutRelated]
    const relatedProductIds = listProductsRelatedAndWithout.map(relatedProduct => relatedProduct.id)

    return getQueryWhere(currentProduct, selectedCategory?.id, relatedProductIds, gender)
  }

  const getProductsFirstPage = async (product?: IProduct) => {
    const currentProduct = currentProductsMap?.[selectedCategory?.type.toLowerCase() as string]
    const hasProductSelectedByCategory = currentProduct?.category?.id === selectedCategory?.id

    const isFromTryonRelated = stateCurrentTryon?.origin === 'start' || stateCurrentTryon?.origin === 'automix'

    let queryProps = getQueryPropsDefault(getWhere(product))
    let typeQuery = 'default' as TTypeQuery

    if (isFromTryonRelated && hasProductSelectedByCategory && product) {
      typeQuery = 'related'
      queryProps = getQueryPropsRelated({ currentProduct: product, selectedCategory, gender })
    }

    setTypeQueryProduct(typeQuery)

    setNotifyNetwork(true)

    await fetchProductsData(queryProps)

    setNotifyNetwork(false)
  }

  const handleClear = () => {
    removeTryon({ setState: clearTryonStates })
    resetMeasurements({ data: ['stateCurrentMeasurements'], setState: clearMeasurementsStates })

    clearCombineTimeout()
    Tracking.logEvent('COMBINE_CLEAN', {
      widget: true,
    })
  }

  const createTryon: TCreateTryon = async ({ product, category }) => {
    const { data: partner } = await getPartner()
    const isFullCategory = category === 'FULL'
    const model = getCurrentModel()
    const data: TStartTryon['data'] = {
      from: 'combination',
      idModel: model.id,
      products: {
        ...(!isFullCategory && { ...stateCurrentTryon?.products }),
        [category.toLowerCase()]: product,
      },
      isUnselect: !product,
      isAutomix: false,
      baseProduct: product && category,
      upscale: partner?.upscale,
    }

    if (!isFullCategory && data.products.full) {
      data.products.full = undefined
    }

    if (!data.products.top && !data.products.bottom && !data.products.full) {
      removeTryon({ setState: clearTryonStates })
      resetMeasurements({ data: ['stateCurrentMeasurements'], setState: clearMeasurementsStates })

      Tracking.logEvent('TRY_ON', {
        avatar: model.id,
        avatar_type: model.type === 'SELF_MODEL' ? 'self' : 'pre',
        unselect: true,
        automix: false,
        start: false,
        widget: true,
      })

      return
    }

    setNextProductActive(product)

    if (partner?.upscale) {
      finishTryonUpscale()
    }

    startTryon({ data, setState: setTryonState })
  }

  const clearCombineTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
  }

  const selectProduct = (product: IProduct) => {
    const category = selectedCategory?.type as TCategoryType
    const isChecked = currentProductActive?.id !== product?.id

    if (shouldCallTryonFit) setShouldCallTryonFit(false)
    clearCombineTimeout()
    setNotifyNetwork(false)
    createTryon({ product: isChecked ? product : undefined, category })

    if (isChecked) {
      timeoutRef.current = setTimeout(() => {
        setMinimized(true)
      }, 4000)
    }
  }

  const productsListReset = () => {
    setProductsListCurrentPage(1)
    setProductsTemporary(undefined)
    setProductsListData(undefined)
    setProductListTemporaryIsLastPage(false)
    setProductsListIsLastPage(false)
  }

  const getListCategories = () => {
    setComponentDisabled(true)

    const startProduct = getBaseProduct()
    const categories = categoriesData?.filter(item => item.hasProducts && item.category).map(item => item.category)

    if (!categories?.length) {
      setCategoryListData([])
      setProductsTemporary([])
      return
    }

    const selected = startProduct ? { ...startProduct?.category, name: startProduct?.category.name } : categories[0]

    setCategoryListData(categories)
    setSelectedCategory({ ...selected })
  }

  const selectCategory = (item: ICategoryItem) => {
    clearCombineTimeout()

    if (selectedCategory?.id === item.id) return

    setComponentDisabled(true)
    setSelectedCategory(item)
    productsListReset()
  }

  const getCategoriesSelected = (lastTryon?: ILastTryon) => {
    const list: TCurrentProductsMap = {
      top: lastTryon?.top,
      bottom: lastTryon?.bottom,
      full: lastTryon?.full,
    }

    return Object.keys(list)
      .map(category => ({ id: list[category]?.category.id }))
      .filter(category => category?.id)
  }

  const getMoreProducts = async (page: number) => {
    setProductsListCurrentPage(page)
    setProductListTemporaryIsLastPage(productListIsLastPage)

    setPaginationLoading(true)

    const isTypeRelated = typeQueryProduct === 'related'

    const {
      data: { product: products },
    } = await fetchMoreProductsData(
      getQueryPropsDefault({
        offset: (page - (isTypeRelated ? 2 : 1)) * PRODUCTS_PER_PAGE,
        limit: PRODUCTS_PER_PAGE + 1,
        where: getWhere(currentProductActive).where,
      }),
    )

    const countProductTemporary = productsTemporary?.length as number
    const nextPageCount: number = isTypeRelated ? countProductTemporary - (page - 1) * PRODUCTS_PER_PAGE : 0

    const isLastPage = products.length + nextPageCount < PRODUCTS_PER_PAGE + 1

    if (isLastPage) {
      setProductsListIsLastPage(true)
      setProductListTemporaryIsLastPage(true)
    }

    const listFinaleProducts = [
      ...(uniqueProducts(productsListData) as IProduct[]),
      ...products.slice(0, PRODUCTS_PER_PAGE),
    ]

    setProductsListData(listFinaleProducts)
    setProductsTemporary(listFinaleProducts)

    setPaginationLoading(false)
  }

  const isTryonChange = () =>
    currentProductsMap?.top?.id !== lastTryon?.top?.id ||
    currentProductsMap?.bottom?.id !== lastTryon?.bottom?.id ||
    currentModelId !== lastTryon?.model

  const updateLastProducts = () => setLastTryon({ ...currentProductsMap, model: currentModelId })

  useEffect(() => {
    if (loadingCategories) return
    getListCategories()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingCategories])

  useEffect(() => {
    if (loadingProductsData || !notifyNetwork) return
    setComponentDisabled(false)

    const products = productsData.slice(0, PRODUCTS_PER_PAGE)
    const currentProduct = currentProductActive || products[0]

    let listSortProducts = [...products]
    let listAllProducts = productsData

    if (typeQueryProduct === 'related') {
      listSortProducts = [...productsDataRelated, ...productsDataWithoutRelated]
      listAllProducts = listSortProducts
    }

    const productList =
      currentProduct?.category?.id === selectedCategory?.id
        ? getSortProduct(currentProduct, listSortProducts)
        : products

    setProductsTemporary(productList)
    setProductsListData(productList)

    if (listAllProducts.length <= PRODUCTS_PER_PAGE) {
      setProductListTemporaryIsLastPage(true)
      return
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingProductsData])

  useEffect(() => {
    if (!selectedCategory) return

    getProductsFirstPage(currentProductActive)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory])

  useEffect(() => {
    setComponentDisabled(!!isLoading)
  }, [isLoading])

  useEffect(() => {
    if (loadingCategories || !isTryonChange() || stateCurrentTryon?.origin === 'start') return

    productsListReset()
    getListCategories()
    updateLastProducts()
    setMinimized(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active])

  useEffect(() => {
    if (!stateTryon?.called || stateTryon?.isLoading || stateTryon?.data?.from !== 'combination') return

    if (stateTryon?.error) {
      productsListReset()
      getProductsFirstPage(currentProductsMap?.[selectedCategory?.name as string])

      return
    }

    updateLastProducts()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateTryon])

  return (
    <Backdrop
      visible={active}
      title={translate('PRODUCTS_BACKDROP_TITLE')}
      testID="backdrop-combine"
      handleClose={() => {
        handleState(false)
        clearCombineTimeout()
        setMinimized(false)
      }}
      layerIndex={4}
      primaryButton={
        <IconButton
          icon={<Icon name="broom" size="15px" color={theme.colors.primary} />}
          disabled={componentDisabled || !Object.keys(currentProductsMap)?.length}
          borderColor={theme.colors.primary}
          size={32}
          onClick={handleClear}
          testID="clear-button"
        />
      }
      minimized={minimized}
      handleMinimize={() => setMinimized(false)}
    >
      <Styled.Container>
        <CategoryList
          data={categoryListData}
          activeItem={selectedCategory}
          selectedItems={getCategoriesSelected(currentProductsMap)}
          onClick={selectCategory}
          disabled={componentDisabled}
        />

        <ProductsList
          data={productsTemporary}
          selectedItem={isLoading ? nextProductActive : currentProductActive}
          showTag={!isLoading}
          currentPage={productListCurrentPage}
          isLastPage={productListTemporaryIsLastPage}
          isLoading={paginationLoading}
          disabled={componentDisabled}
          onSelected={selectProduct}
          onNextPage={getMoreProducts}
        />
      </Styled.Container>
    </Backdrop>
  )
}
