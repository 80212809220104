import { useCallback } from 'react'

import { useLazyQuery } from '@apollo/client'

import { IPartner } from '~/entities'

import { GET_PARTNER, IQueryData, IQueryPartner, IQueryVars } from '.'

export const useQueryPartner = (): IQueryPartner => {
  const [fetchPartner, { data, called, loading, error }] = useLazyQuery<IQueryData, IQueryVars>(GET_PARTNER)

  const fetch = useCallback(
    (): Promise<IPartner> =>
      new Promise(async (resolve, reject) => {
        const { data, error } = await fetchPartner({
          variables: { api_key: localStorage.getItem('@doris:partner_apikey') as string },
        })

        if (!data?.partner?.length || error) {
          reject(error || new Error('Partner not found'))

          return
        }

        resolve(data.partner[0])
      }),
    [fetchPartner],
  )

  return {
    fetch,
    data,
    called,
    loading,
    error,
  }
}
