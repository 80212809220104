import { FC, useEffect, useRef } from 'react'

import { useAutomix } from '~/hooks/useAutomix'
import { useCurrentModel } from '~/hooks/useCurrentModel'
import { ICurrentModel } from '~/hooks/useCurrentModel/types'
import { usePartner } from '~/hooks/usePartner'
import { useTryon } from '~/hooks/useTryon'

import { useTermsContext } from '~/context/Terms'
import { useTryonContext } from '~/context/Tryon'

import { AnimationZoomOut } from '~/components/Animations'
import AutoMixButton from '~/components/AutoMixButton'
import { TextButton } from '~/components/TextButton'
import { Tooltip } from '~/components/Tooltip'
import Typography from '~/components/Typography'
import { Walkthrough } from '~/components/Walkthrough'

import { IAutomix } from '~/entities'
import { FeatureToggle } from '~/utils/featureToggle'
import { translate } from '~/utils/translate'

import { ButtonContainer, ButtonsWrapper, Container } from './styles'
import { IBottomActionsContainerProps } from './types'

const BottomActionsContainer: FC<IBottomActionsContainerProps> = ({
  onCombineClick,
  onChangeModelClick,
  onRecommendationClick,
  walkthrough,
  visibleButtons,
  setScreenLoading,
  showRecommendationTooltip,
  onRecommedantionTooltipHidden,
  onRecommedantionTooltipVisible,
}) => {
  const combination = useRef<IAutomix>()
  const { getCombination, setCombination } = useAutomix()
  const { getPartner } = usePartner()
  const { getCurrentModel } = useCurrentModel()
  const { step, isDone, handleNavigate, handleSkip } = walkthrough
  const { terms } = useTermsContext()
  const { setTryonState, stateTryon } = useTryonContext()
  const { startTryon } = useTryon()
  const isRecommendationToggle = FeatureToggle.get({
    environmentVariable: 'REACT_APP_FEATURE_FLAGS',
    value: 'recommendation',
  })

  const walkthroughActive = step <= 2 && !isDone && terms

  const handleSuggestion = async () => {
    if (isRecommendationToggle) {
      onRecommendationClick(true)

      return
    }

    try {
      setScreenLoading(true)

      combination.current = await getCombination({ model: getCurrentModel() as ICurrentModel })

      const { data: partner } = await getPartner()

      startTryon({
        data: {
          idModel: getCurrentModel().id,
          from: 'automix',
          isAutomix: true,
          products: combination.current.products,
          upscale: partner?.upscale,
        },
        setState: setTryonState,
      })
    } catch (e) {
      setScreenLoading(false)

      // eslint-disable-next-line no-console
      console.error(e)
    }
  }

  useEffect(() => {
    if (!stateTryon?.called || stateTryon?.isLoading || stateTryon?.error || stateTryon?.data?.from !== 'automix')
      return

    setCombination({ model: getCurrentModel() as ICurrentModel, combination: combination.current as IAutomix })
  }, [getCurrentModel, setCombination, stateTryon])

  return (
    <Container active={walkthroughActive} data-testid="bottom-actions-container">
      <ButtonsWrapper>
        <ButtonContainer data-testid="button-container" active={step === 0}>
          <AnimationZoomOut visible={visibleButtons}>
            <TextButton testID="combine-button" textTransform="capitalize" onClick={onCombineClick} disabled={!isDone}>
              {translate('BOTTOM_ACTIONS_COMBINE')}
            </TextButton>
          </AnimationZoomOut>
        </ButtonContainer>

        <ButtonContainer data-testid="button-container" active={step === 1}>
          <AnimationZoomOut visible={visibleButtons}>
            <Tooltip
              content={
                <Typography align="center" type="prefix">
                  {translate('RECOMMENDATION_TOOLTIP')}
                </Typography>
              }
              active={!!showRecommendationTooltip}
              width={190}
              padding="8px 20px"
              id="recommendation"
              place="top"
              arrowWidth={10}
              borderRadius="24px"
              forceWidth
              onHidden={onRecommedantionTooltipHidden}
              onVisible={onRecommedantionTooltipVisible}
              timer={5000}
            >
              <AutoMixButton onClick={handleSuggestion} disabled={stateTryon?.isLoading || !isDone} />
            </Tooltip>
          </AnimationZoomOut>
        </ButtonContainer>

        <ButtonContainer data-testid="button-container" active={step === 2}>
          <AnimationZoomOut visible={visibleButtons}>
            <TextButton
              testID="change-model-button"
              textTransform="initial"
              onClick={onChangeModelClick}
              disabled={!isDone}
            >
              {translate('BOTTOM_ACTIONS_CHANGE_MODEL')}
            </TextButton>
          </AnimationZoomOut>
        </ButtonContainer>
      </ButtonsWrapper>

      {walkthroughActive && <Walkthrough step={step} onNavigate={handleNavigate} onSkip={handleSkip} />}
    </Container>
  )
}

export default BottomActionsContainer
